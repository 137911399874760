import { noop } from 'lodash';
import Graticule, { Options } from 'ol/layer/Graticule';
import Stroke from 'ol/style/Stroke';
import MikeVisualizerStore from '../store/MikeVisualizerStore';


import proj4 from 'proj4';
import { applyTransform } from 'ol/extent';
import { get as getProjection, getTransform } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import View from 'ol/View';

const { getState } = MikeVisualizerStore;

export interface IAddGraticuleHandler {
  show?: () => void;
  hide?: () => void;
  toggle?: () => boolean;
  showOutline?: () => void;
  hideOutline?: () => void;
  toggleOutline?: () => boolean;
  addToMap?: () => void;
  removeFromMap?: () => void;
  error?: string;
  layers?: {
    graticule: Graticule;
    outline: Graticule;
  };
}

export interface IAddGraticule {
  visible?: boolean;
  addToMap?: boolean;
  graticuleOptions?: Partial<Options>;
  graticuleOutlineOptions?: Partial<Options>;
}

/**
 * Adds coordinate grid overlay / Graticule to the map and returns a handler.
 *
 * @param options
 * @param options.visible default is true
 * @param options.addToMap default is true
 * @param options.graticuleOptions OpenLayers Graticule Options for grid
 * @param options.graticuleOutlineOptions OpenLayers Graticule Options for grid outline
 * @returns IAddGraticuleHandler
 */
export const addGraticuleExperiment = async (options?: IAddGraticule): Promise<IAddGraticuleHandler> => {



  const {  addToMap = true  } =
    options || {};
  const { baseMapPromise, epsgCode } = getState();

  // Handle constraints:
  if (!baseMapPromise) {
    const error = `No baseMapPromise in addGraticuleExperiment`;
    console.error(error);
    return { error };
  }
  const baseMap = await baseMapPromise;
  if (!baseMap) {
    const error = `No baseMap in addGraticuleExperiment`;
    console.error(error);
    return { error };
  }
 
   const response = await  fetch('https://epsg.io/?format=json&q=' + epsgCode)
   const res = await response.json();

    const results = res.results;
      let r = {code: "", name: "", proj4def: "", bbox: []}
      if (results && results.length > 0) {
        for (let i = 0, ii = results.length; i < ii; i++) {
          const result = results[i];
          if (result) {
            const code = result.code;
            const name = result.name;
            const proj4def = result.proj4;           
            if (
              code &&
              code.length > 0 &&
              proj4def &&
              proj4def.length > 0 &&
              result.bbox &&
              result.bbox.length === 4
            ) {
              r = {code, name, proj4def, bbox: result.bbox}  
            }
          }
        }
      }
    
    const newProjCode = 'EPSG:' + epsgCode;
    proj4.defs(newProjCode, r.proj4def);
    register(proj4);
    const newProj = getProjection(newProjCode);
    const fromLonLat = getTransform('EPSG:4326', newProj);

    const bbox  = r.bbox
    
    let worldExtent: any = [bbox[1], bbox[2], bbox[3], bbox[0]];
    newProj.setWorldExtent(worldExtent);
  
    // approximate calculation of projection extent,
    // checking if the world extent crosses the dateline
    if (bbox[1] > bbox[3]) {
      worldExtent = [bbox[1], bbox[2], bbox[3] + 360, bbox[0]];
    }
    const extent = applyTransform(worldExtent, fromLonLat, undefined, 8);
    newProj.setExtent(extent);
    const newView = new View({
     projection: newProj,
    });
    baseMap.setView(newView);
    newView.fit(extent); 

  

  const graticule = new Graticule({
    // the style to use for the lines, optional.
    strokeStyle: new Stroke({
      color: 'rgba(0,0,0,0.7)',
      // color: 'rgba(0,255,255,0.7)',
      width: 1,
      // width: 4,
      // lineDash: [7, 3],
      // lineDash: [0.5, 4],
      lineCap: 'butt',
    }),
    /* strokeStyle: new Stroke({
      color: 'rgba(255,120,0,0.9)',
      width: 2,
      lineDash: [0.5, 4],
    }), */
    showLabels: true,
    visible: true,
    wrapX: false,
  });
  
  const graticule2 = new Graticule({
    // the style to use for the lines, optional.
    strokeStyle: new Stroke({
      color: 'rgba(255,255,255,0.7)',
      // color: 'rgba(0,0,0,0.7)',
      width: 4,
      lineCap: 'butt',
    }),
    showLabels: false,
    visible: true,
    wrapX: false,
  });
  // const graticule2 = undefined;
  

  // Add to map:
  if (addToMap) {
    baseMap.addLayer(graticule);
    baseMap.addLayer(graticule2);
  }

  // Return the handler:
  return {
    show: () => {
        graticule.setVisible(true);
        graticule2.setVisible(true);
    },
    hide: () => {
        graticule.setVisible(false);
      graticule2.setVisible(false);
    },
    toggle: () => {
        graticule.setVisible(!graticule.getVisible());
      graticule2.setVisible(!graticule2.getVisible());
      return graticule.getVisible();
    },
    showOutline: () => {
        graticule2.setVisible(true);
    },
    hideOutline: () => {
        graticule2.setVisible(false);
    },
    toggleOutline: () => {
        graticule2.setVisible(!graticule2.getVisible());
      return graticule2.getVisible();
    },
    addToMap: () => {
      baseMap.addLayer(graticule2);
      baseMap.addLayer(graticule);
    },
    removeFromMap: () => {
      baseMap.removeLayer(graticule2);
      baseMap.removeLayer(graticule);
    },
    layers: {
      graticule: graticule,
      outline: graticule2,
    },
  };
}; // addGraticuleExperiment

// The weirdest bug; compile error unless this:
try {
  noop();
} catch (error) {
  console.error(`error`, error);
}
