export enum ENotificationTypes {
  ERROR = 'Error',
  MAINTENANCE = 'Maintenance',
  NEWS = 'News',
  WARNING = 'Warning',
}

export const NotificationTypes = {
  ERROR: ENotificationTypes.ERROR,
  MAINTENANCE: ENotificationTypes.MAINTENANCE,
  NEWS: ENotificationTypes.NEWS,
  WARNING: ENotificationTypes.WARNING,
};

export interface INotification {
  /**
   * Number of milliseconds since midnight Jan 1 1970.
   * Internally used for sorting.
   */
  time: number;
  /**
   * Individual title shown for the notification.
   */
  title: string;
  /**
   * The identifier.
   */
  id?: string;
  /**
   * Optional message shown below the title.
   * Defaults to formatted time value.
   */
  message?: string;
  /**
   * Shows Link componwnts when true.
   */
  link?: boolean; // Set true to add a Link
  /**
   * Label of the Link.
   * Defaults to 'See details'.
   */
  linkLabel?: string;
  /**
   * Type.
   */
  type?: ENotificationTypes;
}

export interface INotificationPerProject {
  [projectId: string]: Array<INotification>;
}
