import React, { useState, useEffect, useRef } from 'react';
import SpinnersButton, { SpinnersButtonIcon, IButtonTooltips } from './Spinners/SpinnersButton';
import SpinnersDropdown from './Spinners/SpinnersDropdown';
import { IProgressItem } from './IProgressItem';
import { ClickAwayListener } from '@material-ui/core';
import { wrapperStyle } from '../mike-topbar-dropdown';

export const SPINNER_TIMEOUT = 6000;
export const PROGRESS_ITEM_TIMEOUT = 6000;

interface IProps extends React.HTMLAttributes<HTMLElement> {
  progressItems: Array<IProgressItem>;
  spinnerTimeout?: number;
  tooltipTitles?: IButtonTooltips;
  onClickItemButton?: (id: string) => {};
}

/**
 * @name MikeTopbarProgressSpinner
 * @summary Shows progress in the topbar. Typically related to notifications on currently-running actions.
 */
export const MikeTopbarProgressSpinner = (props: IProps) => {
  const { progressItems, className, spinnerTimeout, tooltipTitles, onClickItemButton } = {
    spinnerTimeout: SPINNER_TIMEOUT,
    ...props,
  };

  const [showDropdown, setShowDropdown] = useState(true);
  const hideTORef = useRef<NodeJS.Timeout>();
  const resetCheckTORef = useRef<NodeJS.Timeout>();
  const resetAlertTORef = useRef<NodeJS.Timeout>();
  const timeouts = useRef<Array<NodeJS.Timeout>>([]);
  const toggleDropdown = () => {
    clearTimeout(hideTORef.current as NodeJS.Timeout);
    setShowDropdown(!showDropdown);
  };

  const [btnIcon, setBtnIcon] = useState<SpinnersButtonIcon>('spinner');
  // Find out if progressItems are all done:
  const allItemsDone: boolean =
    progressItems.every((itm) => itm.done === true) && progressItems.length > 0;
  // Find out if progressItems have any errors:
  const itemsHasError: number = progressItems.filter((itm) => itm.error === true).length;

  // Show SpinnersDropdown again when there are new items:
  const prevItemsLength = useRef<number>(0); // <- Hold previous items length here
  if (progressItems.length > prevItemsLength.current) {
    setBtnIcon('spinner');
    setShowDropdown(true);
    clearTimeout(hideTORef.current as NodeJS.Timeout);
    hideTORef.current = setTimeout(() => {
      setShowDropdown(false);
    }, spinnerTimeout);
    timeouts.current.push(hideTORef.current);
  }
  prevItemsLength.current = progressItems.length; // <- Store previous items length here

  // Show icon according to all-done state:
  const prevAllItemsDone = useRef<boolean>(); // prevent infinite loop
  if (allItemsDone && !prevAllItemsDone.current) {
    setBtnIcon('checkmark');
    clearTimeout(resetCheckTORef.current as NodeJS.Timeout);
    resetCheckTORef.current = setTimeout(() => {
      setBtnIcon('spinner');
    }, spinnerTimeout);
    timeouts.current.push(resetCheckTORef.current);
    prevAllItemsDone.current = true;
  } else {
    prevAllItemsDone.current = false;
  }

  // Show icon according to error state:
  const prevItemsHasError = useRef<boolean>(); // prevent infinite loop
  if (itemsHasError && !prevItemsHasError.current) {
    setBtnIcon('alert');
    clearTimeout(resetAlertTORef.current as NodeJS.Timeout);
    resetAlertTORef.current = setTimeout(() => {
      setBtnIcon('spinner');
    }, spinnerTimeout);
    timeouts.current.push(resetAlertTORef.current);
    prevItemsHasError.current = true;
  } else {
    prevItemsHasError.current = false;
  }

  useEffect(() => {
    // cleanup at unmount:
    return () => {
      timeouts.current.forEach((to) => clearTimeout(to));
    };
  }, []);

  // Only show tooltips when closed:
  const tooltips: IButtonTooltips | undefined = showDropdown
    ? { spinner: '', alert: '', checkmark: '' }
    : tooltipTitles;

  return (
    <div className={`${wrapperStyle} ${className || ''}`}>
      {progressItems.length > 0 && (
        <>
          <SpinnersButton onClick={toggleDropdown} icon={btnIcon} tooltipTitles={tooltips} />
          {showDropdown && (
            <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
              <div /* this div fixes ref error for ClickAwayListener */>
                <SpinnersDropdown progressItems={progressItems} onClickItemButton={onClickItemButton}/>
              </div>
            </ClickAwayListener>
          )}
        </>
      )}
    </div>
  );
};

export default MikeTopbarProgressSpinner;
