import * as ulp from 'ulp';
import tinycolor from 'tinycolor2';
import { values } from 'lodash-es';

import vtkMapper from 'vtk.js/Sources/Rendering/Core/Mapper';
import vtkColorTransferFunction from 'vtk.js/Sources/Rendering/Core/ColorTransferFunction';
import vtkColorMaps from 'vtk.js/Sources/Rendering/Core/ColorTransferFunction/ColorMaps';
import { ColorMode, ScalarMode } from 'vtk.js/Sources/Rendering/Core/Mapper/Constants';

import MikeVisualizerStore from './store/MikeVisualizerStore';
import MikeVisualizerUtil from './MikeVisualizerUtil';

import { IColorRange, IDrawnDataGradientSettings } from './IMikeVisualizerModels';
import {
  IValueColor,
  IThreeDRenderElement,
  IElementDataArray,
  ISelectAllColorMap,
} from './IMikeVisualizerModels';
import { IRepresentation } from './models/IRepresentation';
import { getConfiguration } from './MikeVisualizerConfiguration';
import { DATA_ARRAY_TYPES } from './MikeVisualizerConstants';

const { getState, setState } = MikeVisualizerStore;
const { rendererReady, containsCellData, containsPointData } = MikeVisualizerUtil;

/**
 * Module containing methods that can modify the appearance of a geometry:
 * color, gradient, opacity, visibility, etc.
 *
 * @module MikeVisualizerCosmetic
 * @version 1.0.0
 */

/**
 * Darken the color a given amount, from 0 to 100. Providing 100 will always return black.
 *
 * @param color
 * @param darkenBy Darken color by this amount.
 *
 * @private (could be a util later)
 */
const _getDarkColor = (color: Array<number>, darkenBy = 30) => {
  const [r, g, b, a] = color;

  // NB: should maintain original alpha.
  return [
    ...values(
      tinycolor(`rgba(${r * 255}, ${g * 255}, ${b * 255}, ${a})`)
        .darken(darkenBy)
        .toRgb()
    )
      .slice(0, 3)
      .map((val) => val / 255),
    a,
  ];
};

/**
 * Highlights an actor by id, if that actor doesn't have a gradient applied.
 * Only one element can be highlithed at a time. It is meant to be a temporary state, that shows the relationship between i.e. a text list of elements and their visulisations.
 * Highlighting darkens the current color of the element.
 * If the element is 'selected', a darkened form of the selected color will be applied.
 * It has no effect on elements that have gradients applied.
 *
 * @param elementId The id of the element to highlight.
 *
 * @public
 */
const highlight = (
  elementId: string,
  options?: {
    edgeHighlightColor?: Array<number>;
    surfaceHighlightColor?: Array<number>;
  }
) => {
  const { highlight: highlightConfig, colors } = getConfiguration();
  const { renderWindow, renderedElements, highlightedElementId, selectedElementIds } = getState();
  const renderedElement = renderedElements.find(({ id }) => id === elementId);

  // Prevent highlighting elements that have a gradient applied.
  if (renderedElement && renderedElement.gradientApplied) {
    return false;
  }

  if (!renderedElement) {
    return false;
  }

  if (elementId === highlightedElementId) {
    return false;
  }

  const isSelected = selectedElementIds.findIndex((id) => id === elementId) !== -1;
  const edgeColor =
    options && options.edgeHighlightColor
      ? options.edgeHighlightColor
      : !isSelected
      ? self._getDarkColor(renderedElement.edgeColor)
      : self._getDarkColor(colors.select.edge);
  const surfaceColor =
    options && options.surfaceHighlightColor
      ? options.surfaceHighlightColor
      : !isSelected
      ? self._getDarkColor(renderedElement.surfaceColor)
      : self._getDarkColor(colors.select.surface);

  const highlightSuccessful = self._colorElement(elementId, edgeColor, surfaceColor);

  if (highlightSuccessful) {
    self._setLineWidthToElement(elementId, highlightConfig.lineWidth);
    setState({ highlightedElementId: elementId });
    renderWindow.render();
    return true;
  }

  return false;
};

/**
 * Unsets the highlighted element.
 *
 * @param elementId The id of the element to unhighlight.
 *
 * @public
 */
const unhighlight = (elementId: string) => {
  const { colors } = getConfiguration();
  const { renderedElements, renderWindow, highlightedElementId, selectedElementIds } = getState();
  const renderedElement = renderedElements.find(({ id }) => id === elementId);

  if (!renderedElement) {
    return false;
  }

  if (highlightedElementId !== elementId) {
    return false;
  }

  const isSelected = selectedElementIds.findIndex((id) => id === elementId) !== -1;
  const edgeColor = !isSelected ? renderedElement.edgeColor : colors.select.edge;
  const surfaceColor = !isSelected ? renderedElement.surfaceColor : colors.select.surface;

  const unhighlightSuccessful = _colorElement(elementId, edgeColor, surfaceColor);

  if (unhighlightSuccessful) {
    self._setLineWidthToElement(elementId, 1);
    setState({ highlightedElementId: null });
    renderWindow.render();
    return true;
  }

  return false;
};

/**
 * Makes a list of element shown.
 *
 * @param elementIds The ids of elements to show.
 *
 * @public
 */
const showElements = (elementIds: Array<string>) => {
  if (!rendererReady()) {
    return false;
  }

  const { hiddenElementIds, renderWindow } = getState();

  setState({
    hiddenElementIds: hiddenElementIds.filter((eId) => !elementIds.includes(eId)),
  });
  elementIds.forEach((eId) => self._show(eId));
  renderWindow.render();
  return true;
};

/**
 * Shows an element.
 *
 * @param elementId
 *
 * @public
 */
const showElement = (elementId: string) => {
  if (!rendererReady()) {
    return false;
  }

  const { hiddenElementIds, renderWindow } = getState();
  self._show(elementId);
  setState({
    hiddenElementIds: hiddenElementIds.filter((eId) => eId !== elementId),
  });
  renderWindow.render();
  return true;
};

/**
 * Makes a list of element hidden.
 * NB: replaces previous hidden elements.
 *
 * @param elementIds The ids of elements to hide.
 *
 * @public
 */
const hideElements = (elementIds: Array<string>) => {
  if (!rendererReady()) {
    return false;
  }

  setState({ hiddenElementIds: elementIds });
  elementIds.forEach((eId) => self._hide(eId));
  getState().renderWindow.render();

  return true;
};

/**
 * Hides an element.
 *
 * @param elementId
 *
 * @public
 */
const hideElement = (elementId: string) => {
  if (!rendererReady()) {
    return false;
  }

  const { hiddenElementIds, renderWindow } = getState();
  self._hide(elementId);
  setState({ hiddenElementIds: [...hiddenElementIds, elementId] });
  renderWindow.render();

  return true;
};

/**
 * Applies a color range to an actor. Requires data separately.
 * Works both on points or cell data.
 *
 * @param { vtkEnhancedActor } actor The actor instance to apply gradient to.
 * @param { vtkData } vtpDataPiece The data piece to map the gradient by.
 * @param actorEdgeColor The fallback edge color.
 * @param actorSurfaceColor The fallback surface color.
 * @param [gradientSettings] Optional. Gradient settings,  @see IDrawnDataGradientSettings. Will fallback to default
 * @param [gradientAttributeName] Use this attribute to apply a gradient color. By default the first available attribute will be used.
 *
 * @return { vtkActor } resulting actor.
 *
 * @public
 */
function setGradientToActor(
  actor,
  vtpDataPiece,
  actorEdgeColor: Array<number>,
  actorSurfaceColor: Array<number>,
  gradientSettings?: IDrawnDataGradientSettings,
  gradientAttributeName?: string,
  colorRange?: IColorRange,
) {
  const { gradientSettings: defaultGradientSettings } = getConfiguration();

  const containsCellDataArrays = containsCellData(vtpDataPiece);
  const containsPointDataArrays = containsPointData(vtpDataPiece);

  if (containsCellDataArrays || containsPointDataArrays) {
    const dataArrays: Array<IElementDataArray> = MikeVisualizerUtil.getDataArrays(vtpDataPiece);

    let dataArray;
    if (dataArrays) {
      // If there is some array of cell or point data (i.e. Area, Quality, etc), color it based on a range.
      // Use the provided attribute name or default to the first.
      dataArray = gradientAttributeName
        ? (dataArrays.find((arr) => arr.id === gradientAttributeName) as IElementDataArray)
        : (dataArrays[0] as IElementDataArray);
    }

    if (!dataArray) {
      // If a bad attributeName is passed, revert to basic colors.
      return self.setBasicColorToActor(actor, vtpDataPiece, actorEdgeColor, actorSurfaceColor);
    }

    const { gradientPreset, gradientColorMap } = gradientSettings || defaultGradientSettings;

    const colorArrayRange = dataArray.range;
    const colorByArrayName = dataArray.id;
    const colorMode = ColorMode.MAP_SCALARS;
    const scalarMode =
      dataArray.type === DATA_ARRAY_TYPES.CELLDATA
        ? ScalarMode.USE_CELL_FIELD_DATA
        : ScalarMode.USE_POINT_FIELD_DATA;
    const dataRange = [colorArrayRange[0], colorArrayRange[1]];
    const preset =
      gradientColorMap ||
      vtkColorMaps.getPresetByName(gradientPreset || defaultGradientSettings.gradientPreset);
    const lookupTable = vtkColorTransferFunction.newInstance();

    const mapper = vtkMapper.newInstance({
      interpolateScalarsBeforeMapping: false,
      useLookupTableScalarRange: true,
      lookupTable,
      scalarVisibility: true,
    });

    lookupTable.setVectorModeToMagnitude();
    lookupTable.applyColorMap(preset);

    let mappingRange = [...dataRange];  

    // If a colorRange is defined it will be prioritized
    if (colorRange){
      if (colorRange.belowRangeColor) {
        lookupTable.setUseBelowRangeColor(true);
        lookupTable.setBelowRangeColor(colorRange.belowRangeColor);
      } else {
        lookupTable.setUseBelowRangeColor(false);
      }
      if (colorRange.aboveRangeColor) {
        lookupTable.setUseAboveRangeColor(true);
        lookupTable.setAboveRangeColor(colorRange.aboveRangeColor);
      } else {
        lookupTable.setUseAboveRangeColor(false);
      }
      if (colorRange.colorMappingRange) {
        mappingRange = [...colorRange.colorMappingRange];
      }
    }
    // if aboveRangeColor is defined it will be used
    else {
      // if belowRangeColor is defined it will be used
      if (preset.belowRangeColor) {
        lookupTable.setUseBelowRangeColor(true);
        lookupTable.setBelowRangeColor(preset.belowRangeColor);
      } else {
        lookupTable.setUseBelowRangeColor(false);
      }
      if (preset.aboveRangeColor) {
        lookupTable.setUseAboveRangeColor(true);
        lookupTable.setAboveRangeColor(preset.aboveRangeColor);
      } else {
        lookupTable.setUseAboveRangeColor(false);
      }

      // If a mapping range is defined it will be used
      if (preset.colorMappingRange) {      
        mappingRange = [...preset.colorMappingRange];
      }
    }    

    // vtk setMappingRange does not allow min === max .
    if (mappingRange[0] === mappingRange[1]) {
      mappingRange[1] = ulp.nextUp(mappingRange[1]);
    }

    lookupTable.setMappingRange(mappingRange[0], mappingRange[1]);
    lookupTable.build();
    lookupTable.updateRange();
    mapper.setLookupTable(lookupTable);
    

    mapper.set({
      colorByArrayName,
      colorMode,
      scalarMode,
    });

    actor.setMapper(mapper);
    actor.getProperty().setOpacity(self._getOpacity(actorEdgeColor, actorSurfaceColor));
    mapper.setInputData(vtpDataPiece);

    // Update state with information regarding the gradient.
    const { renderedElements } = getState();
    const renderedElementIndex = renderedElements.findIndex(({ id }) => id === actor.getActorId());

    if (renderedElementIndex !== -1) {
      const renderedElement: IThreeDRenderElement = {
        ...renderedElements[renderedElementIndex],
        gradientSettings,
        gradientAttributeName,
        gradientApplied: true,
      };

      setState({
        renderedElements: [
          ...renderedElements.slice(0, renderedElementIndex),
          renderedElement,
          ...renderedElements.slice(renderedElementIndex + 1),
        ],
      });
    }

    return actor;
  }

  // Fallback: apply basic color.
  return self.setBasicColorToActor(actor, vtpDataPiece, actorEdgeColor, actorSurfaceColor);
}

/**
 * Colors an actor using edge colors. Requires data separately.
 * Simple way of coloring as an alternative to gradients.
 *
 * TODO hevo we should handle opacity. Now it relies on current opcaity, which might be zero.
 *
 * @param { vtkEnhancedActor} actor The actor to apply basic color to.
 * @param { vtkData } vtpDataPiece Actor data to apply color on.
 * @param actorEdgeColor Rgba color array for the edge.
 * @param actorSurfaceColor Rgba color array for the surface.
 *
 * @public
 */
const setBasicColorToActor = (
  actor,
  vtpDataPiece,
  actorEdgeColor: Array<number>,
  actorSurfaceColor: Array<number>
) => {
  // Default, color by element data instead.
  const mapper = vtkMapper.newInstance({
    interpolateScalarsBeforeMapping: false,
    scalarVisibility: false,
  });

  actor.getProperty().setEdgeColor(actorEdgeColor[0], actorEdgeColor[1], actorEdgeColor[2]);
  actor.getProperty().setColor(actorSurfaceColor[0], actorSurfaceColor[1], actorSurfaceColor[2]);
  actor.setMapper(mapper);

  mapper.setInputData(vtpDataPiece);

  // Update state with information regarding the gradient (not applied)
  const { renderedElements } = getState();
  const renderedElementIndex = renderedElements.findIndex(({ id }) => id === actor.getActorId());

  if (renderedElementIndex !== -1) {
    const renderedElement: IThreeDRenderElement = {
      ...renderedElements[renderedElementIndex],
      edgeColor: actorEdgeColor,
      surfaceColor: actorSurfaceColor,
      gradientApplied: false,
      gradientAttributeName: undefined,
      gradientSettings: undefined,
    };

    setState({
      renderedElements: [
        ...renderedElements.slice(0, renderedElementIndex),
        renderedElement,
        ...renderedElements.slice(renderedElementIndex + 1),
      ],
    });
  }

  return true;
};

/**
 * Updates an existing element (in this case, actor) gradient.
 *
 * @param elementId The actor to update gradient for.
 * @param [gradientAttributeName] Optional name of the data array to update. If none provided, it will clear the previous gradient.
 * @param [gradientSettings] Optional gradient settings (color preset etc). Falls back to default settings.
 *
 * @public
 */
const updateElementGradient = (
  elementId: string,
  gradientAttributeName?: string,
  gradientSettings?: IDrawnDataGradientSettings,
  colorRange?: IColorRange,
) => {
  const updated = _updateElementGradient(elementId, gradientAttributeName, gradientSettings, colorRange)
  if (updated){
    const { renderWindow } = getState();  
    renderWindow.render();
    return true;
  }

  return false;
};

/**
 * Updates an existing element (in this case, actor) gradient.
 *
 * @param elementIds The actor to update gradient for.
 * @param [gradientAttributeName] Optional name of the data array to update. If none provided, it will clear the previous gradient.
 * @param [gradientSettings] Optional gradient settings (color preset etc). Falls back to default settings.
 *
 * @public
 */
const updateElementsGradient = (
  elementIds: Array<string>,
  gradientAttributeName?: string,
  gradientSettings?: IDrawnDataGradientSettings,
  colorRange?: IColorRange,
) => {
  elementIds.forEach(elementId => {
    _updateElementGradient(elementId, gradientAttributeName, gradientSettings, colorRange)
  }); 
  const { renderWindow } = getState();  
  renderWindow.render();   
};

const _updateElementGradient = (
  elementId: string,
  gradientAttributeName?: string,
  gradientSettings?: IDrawnDataGradientSettings,
  colorRange?: IColorRange,
) => {
  const { renderer, renderedElements } = getState();
  const renderedElement = renderedElements.find(({ id }) => id === elementId);
  const { gradientPreset, gradientColorMap } = gradientSettings
    ? gradientSettings
    : { gradientPreset: '', gradientColorMap: '' };

  if (renderedElement) {
    const {
      edgeColor,
      surfaceColor,
      gradientSettings: currentGradientSettings,
      gradientAttributeName: currentAttributeName,
    } = renderedElement;
    const {
      gradientPreset: currentPreset,
      gradientColorMap: currentColorMap,
    } = currentGradientSettings
      ? currentGradientSettings
      : { gradientPreset: '', gradientColorMap: '' };

    if (
      gradientAttributeName !== currentAttributeName ||
      gradientPreset !== currentPreset ||
      gradientColorMap !== currentColorMap ||
      colorRange
    ) {
      // Only update if name or preset actually changed.
      const actor = renderer.getActors().find((a) => a.getActorId() === elementId);

      if (!actor) {
        console.info('Tried to set gradient on an element that does not exist.');
        return false;
      }

      const vtpData = actor.getMapper().getInputData();

      if (gradientAttributeName) {
        self.setGradientToActor(
          actor,
          vtpData,
          edgeColor,
          surfaceColor,
          gradientSettings,
          gradientAttributeName,
          colorRange
        );
      } else if (gradientColorMap) {
        // If there's a selectAll colortMap set basic surface color to the selectColor.
        const selectAllColorMap = gradientColorMap as ISelectAllColorMap;

        if (selectAllColorMap) {
          self.setBasicColorToActor(actor, vtpData, edgeColor, selectAllColorMap.selectColor);
        } else {
          // If there's no select all color map, revert to basic color.
          self.setBasicColorToActor(actor, vtpData, edgeColor, surfaceColor);
        }
      } else {
        // If there's no attribute name, revert to basic color.
        self.setBasicColorToActor(actor, vtpData, edgeColor, surfaceColor);
      }
    }   

    return true;
  }

  return false;
};

/**
 * Sets opacity, surface & edge color to an element.
 * Opacity is derived from either edge or surface alphas. @see `_getOpacity()`.
 * Note on colors: edge seem to only be relevant for Surface + edge. In all other scenarios surface color is used instead.
 * Note: this is not a stateful change. @see setColorToElement()
 *
 * @param elementId The element to color.
 * @param [edgeColor]
 * @param [surfaceColor]
 *
 * @public
 */
const _colorElement = (
  elementId: string,
  edgeColor?: Array<number>,
  surfaceColor?: Array<number>
) => {
  if (!rendererReady()) {
    return false;
  }

  const { renderer, renderedElements } = getState();
  const element = renderedElements.find((p) => p.id === elementId);

  if (element) {
    const actor = renderer.getActors().find((a) => a.getActorId() === elementId);

    if (actor) {
      const surfaceColorToApply = surfaceColor || element.surfaceColor;
      const edgeColorToApply = edgeColor || element.edgeColor;

      actor.getProperty().setColor(...surfaceColorToApply.slice(0, 3));
      actor.getProperty().setEdgeColor(...edgeColorToApply.slice(0, 3));
      actor.getProperty().setOpacity(self._getOpacity(edgeColorToApply, surfaceColorToApply));

      return true;
    }
  }

  return false;
};

/**
 * Sets line width to element. This might work in some browser...
 * @see https://github.com/Kitware/vtk-js/pull/1336
 * @see https://github.com/Kitware/vtk-js/issues/673
 *
 * @param elementId
 * @param lineWidth
 */
const _setLineWidthToElement = (elementId: string, lineWidth: number) => {
  if (!rendererReady()) {
    return false;
  }

  const { renderer, renderedElements } = getState();
  const element = renderedElements.find((p) => p.id === elementId);

  if (element) {
    const actor = renderer.getActors().find((a) => a.getActorId() === elementId);

    if (actor) {
      actor.getProperty().setLineWidth(lineWidth);

      return true;
    }
  }

  return false;
};

/**
 * Sets a new color for an element and updates state.
 *
 * @param elementId The actor id to set color to.
 * @param edgeColor Rgba color array for the edge.
 * @param surfaceColor Rgba color array for the surface.
 *
 * @public
 */
const setColorToElement = (
  elementId: string,
  edgeColor: Array<number>,
  surfaceColor: Array<number>
) => {
  if (!rendererReady()) {
    return false;
  }

  const { renderer, renderWindow } = getState();
  const actor = renderer.getActors().find((a) => a.getActorId() === elementId);

  if (actor) {
    const actorData = actor.getMapper().getInputData(0);
    self.setBasicColorToActor(actor, actorData, edgeColor, surfaceColor);
    renderWindow.render();
    return true;
  }

  return false;
};

/**
 * Sets a new color and representation for elements and updates state.
 *
 * @param representation
 *
 * @public
 */
const setRepresentationToElements = (
  elementIds: Array<string>,
  representation: IRepresentation,
) => {
  if (!rendererReady()) {
    return null;
  }

  const { renderer, renderWindow, renderedElements } = getState();
  let changedActorIds = Array<string>()
  elementIds.forEach((elementId: string) => {
    const actor = renderer.getActors().find((a) => a.getActorId() === elementId);
    if (actor){      
      // Update representation
      actor.getProperty().setRepresentation(representation.representation);
      actor.getProperty().setEdgeVisibility(representation.edgeVisibility);  
      changedActorIds = [...changedActorIds, elementId]
    }
  })

  if (changedActorIds.length === 0){
    return false;
  }

  // Update state with information regarding the gradient (not applied)
  const updatedRenderedElements = renderedElements.map((re: IThreeDRenderElement) => {
    if (changedActorIds.includes(re.id)){
      return {
        ...re,
        representation,       
      }
    }
    else {
      return re
    }
  }) 

  setState({ renderedElements: updatedRenderedElements });  
  renderWindow.render();
  return true;
};

const setPointSizeToElements = (elementIds: Array<string>, pointSize: number) => {
  if (!rendererReady()) {
    return null;
  }

  const { renderer, renderWindow, renderedElements } = getState();
  let changedActorIds = Array<string>()
  elementIds.forEach((elementId: string) => {
    const actor = renderer.getActors().find((a) => a.getActorId() === elementId);
    if (actor){      
      // Update point size
      actor.getProperty().setPointSize(pointSize);
      changedActorIds = [...changedActorIds, elementId]
    }
  })

  if (changedActorIds.length === 0){
    return false;
  }

  // Update state with information regarding the gradient (not applied)
  const updatedRenderedElements = renderedElements.map((re: IThreeDRenderElement) => {
    if (changedActorIds.includes(re.id)){
      return {
        ...re,
        pointSize,       
      }
    }
    else {
      return re
    }
  }) 

  setState({ renderedElements: updatedRenderedElements });  
  renderWindow.render();
  return true;
}

/**
 * Sets a new color and representation for elements and updates state.
 *
 * @param elementIds The actor id to set color to.
 * @param edgeColor Rgba color array for the edge.
 * @param surfaceColor Rgba color array for the surface.
 *
 * @public
 */
const setColorToElements = (
  elementIds: Array<string>,
  edgeColor: Array<number>,
  surfaceColor: Array<number>, 
) => {
  if (!rendererReady()) {
    return null;
  }

  const { renderer, renderWindow, renderedElements } = getState();
  let changedActorIds = Array<string>()
  elementIds.forEach((elementId: string) => {
    const actor = renderer.getActors().find((a) => a.getActorId() === elementId);
    if (actor){
      const vtpDataPiece = actor.getMapper().getInputData(0);
      // Default, color by element data instead.
      const mapper = vtkMapper.newInstance({
        interpolateScalarsBeforeMapping: false,
        scalarVisibility: false,
      });     
      actor.getProperty().setEdgeColor(edgeColor[0], edgeColor[1], edgeColor[2]);
      actor.getProperty().setColor(surfaceColor[0], surfaceColor[1], surfaceColor[2]);
      actor.setMapper(mapper);
      mapper.setInputData(vtpDataPiece);

      changedActorIds = [...changedActorIds, elementId]
    }
  })

  if (changedActorIds.length === 0){
    return false;
  }

  // Update state with information regarding the gradient (not applied)
  const updatedRenderedElement = renderedElements.map((re: IThreeDRenderElement) => {
    if (changedActorIds.includes(re.id)){
      return {
        ...re,     
        edgeColor,
        surfaceColor,
        gradientApplied: false,
        gradientAttributeName: undefined,
        gradientSettings: undefined,
      }
    }
    else {
      return re
    }
  }) 

  setState({ renderedElements: updatedRenderedElement });  
  renderWindow.render();
  return true;
};

/**
 * Sets a new color and representation for elements and updates state.
 *
 * @param elementIds The actor id to set color to.
 * @param edgeColor Rgba color array for the edge.
 * @param surfaceColor Rgba color array for the surface.
 * @param representation
 *
 * @public
 */
const setColorAndRepresentationToElements = (
  elementIds: Array<string>,
  edgeColor: Array<number>,
  surfaceColor: Array<number>,
  representation: IRepresentation,
) => {
  if (!rendererReady()) {
    return null;
  }

  const { renderer, renderWindow, renderedElements } = getState();
  let changedActorIds = Array<string>()
  elementIds.forEach((elementId: string) => {
    const actor = renderer.getActors().find((a) => a.getActorId() === elementId);
    if (actor){
      const vtpDataPiece = actor.getMapper().getInputData(0);
      // Update representation
      actor.getProperty().setRepresentation(representation.representation);
      actor.getProperty().setEdgeVisibility(representation.edgeVisibility);
      // Default, color by element data instead.
      const mapper = vtkMapper.newInstance({
        interpolateScalarsBeforeMapping: false,
        scalarVisibility: false,
      });     
      actor.getProperty().setEdgeColor(edgeColor[0], edgeColor[1], edgeColor[2]);
      actor.getProperty().setColor(surfaceColor[0], surfaceColor[1], surfaceColor[2]);
      actor.setMapper(mapper);
      mapper.setInputData(vtpDataPiece);

      changedActorIds = [...changedActorIds, elementId]
    }
  })

  if (changedActorIds.length === 0){
    return false;
  }

  // Update state with information regarding the gradient (not applied)
  const updatedRenderedElement = renderedElements.map((re: IThreeDRenderElement) => {
    if (changedActorIds.includes(re.id)){
      return {
        ...re,
        representation,
        edgeColor,
        surfaceColor,
        gradientApplied: false,
        gradientAttributeName: undefined,
        gradientSettings: undefined,
      }
    }
    else {
      return re
    }
  }) 

  setState({ renderedElements: updatedRenderedElement });  
  renderWindow.render();
  return true;
};

/**
 * Gets colors for each value in the list given for the element specified.
 *
 * @param elementId The element to get colors for.
 * @param values An array of  values to get the colors for.
 *
 * @public
 */
const getValueColors = (elementId: string, colorValues: Array<any>): Array<IValueColor> | null => {
  if (!rendererReady()) {
    return null;
  }

  if (!colorValues || !colorValues.length) {
    return [];
  }

  const { renderer, renderedElements } = getState();
  const element = renderedElements.find((p) => p.id === elementId);

  if (element) {
    const actor = renderer.getActors().find((a) => a.getActorId() === elementId);

    if (actor) {
      const lookupTable = actor.getMapper().getLookupTable();

      if (lookupTable.isA('vtkColorTransferFunction')) {
        const opacity = actor.getProperty().getOpacity(); // use the same opacity as in the viewer

        const colors = colorValues.map((value) => {
          const color = new Array(4);
          lookupTable.getAnnotationColor(value, color);
          const rgba = color.map((c) => c * 255);
          rgba[3] = opacity;

          return { value, rgba };
        });

        return colors;
      }
    }
  }

  return [];
};

/**
 * Returns the name of the attribute having applied a gradient for the element specified
 * @param elementId
 *
 * @public
 */
const getGradientAttributeName = (elementId: string): string | null => {
  const { renderer, renderedElements } = getState();
  const element = renderedElements.find((p) => p.id === elementId);

  if (element) {
    const actor = renderer.getActors().find((a) => a.getActorId() === elementId);

    if (actor) {
      return actor.getMapper().getColorByArrayName();
    }
  }

  return null;
};

/**
 * Makes the element with the provided id visible.
 *
 * @param elementId
 *
 * @private
 */
const _show = (elementId: string) => {
  self._setVisibility(elementId, true);
};

/**
 * Makes the element with the provided id hidden.
 *
 * @param elementId
 *
 * @private
 */
const _hide = (elementId: string) => {
  self._setVisibility(elementId, false);
};

/**
 * Changes the visibility of an element.
 *
 * @param elementId The id of the element to set visibility to.
 * @param visibility
 *
 * @private
 */
const _setVisibility = (elementId: string, visibility: boolean) => {
  const { renderer, renderedElements } = getState();
  const element = renderedElements.find((p) => p.id === elementId);

  if (element) {
    const actor = renderer.getActors().find((a) => a.getActorId() === elementId);

    if (actor) {
      actor.setVisibility(visibility);
    }
  }

  return true;
};

/**
 * Figures out the opacity of an element based on edge color and surface color alphas.
 * If a surface color is present, it will be prioritized.
 *
 * @param edgeColor
 * @param surfaceColor
 *
 * @private
 */
const _getOpacity = (edgeColor?: Array<number>, surfaceColor?: Array<number>) => {
  return surfaceColor ? surfaceColor[3] : edgeColor ? edgeColor[3] : 1;
};

/**
 * Sets a xyz scale to an actor.
 *
 * @param { vtkEnhancedActor } actor The actor to apply the scale to.
 * @param xScale The xScale to apply; must be > 1 to be valid in vtk.js
 * @param yScale The yScale to apply; must be > 1 to be valid in vtk.js
 * @param zScale The zScale to apply; must be > 1 to be valid in vtk.js
 *
 * @public
 */
const setScaleToActor = (actor, xScale: number, yScale: number, zScale: number) => {
  try {
    actor.setScale(xScale, yScale, zScale);
    return true;
  } catch (e) {
    console.error('Failed to set scale to actor', e);
    return false;
  }
};

/**
 * Sets a xyz scale to all provided actors.
 *
 * @param { Array<vtkEnhancedActor> } actors The actors to apply the scale to.
 * @param xScale
 * @param yScale
 * @param zScale
 *
 * @public
 */
const setScaleToActors = (actors: Array<any>, xScale: number, yScale: number, zScale: number) => {
  return actors.map((actor) => self.setScaleToActor(actor, xScale, yScale, zScale));
};

/**
 * Updates the representation of an element.
 *
 * @param elementId
 * @param representation
 *
 * @public
 */
const setRepresentation = (elementId: string, representation: IRepresentation) => {
  if (!rendererReady()) {
    return null;
  }

  const { renderer, renderWindow, renderedElements } = getState();
  const actor = renderer.getActors().find((a) => a.getActorId() === elementId);

  if (actor) {
    actor.getProperty().setRepresentation(representation.representation);
    actor.getProperty().setEdgeVisibility(representation.edgeVisibility);

    const renderedElementIndex = renderedElements.findIndex(({ id }) => id === actor.getActorId());

    if (renderedElementIndex !== -1) {
      const renderedElement: IThreeDRenderElement = {
        ...renderedElements[renderedElementIndex],
        representation,
      };

      setState({
        renderedElements: [
          ...renderedElements.slice(0, renderedElementIndex),
          renderedElement,
          ...renderedElements.slice(renderedElementIndex + 1),
        ],
      });
    }

    renderWindow.render();
    return true;
  }

  return false;
};

/**
 * Updates the pointsize of an element.
 *
 * @param elementId
 * @param pointSize
 *
 * @public
 */
const setPointSize = (elementId: string, pointSize: number) => {
  if (!rendererReady()) {
    return null;
  }

  // todo hevo only set if different from current pointsize????

  const { renderer, renderWindow, renderedElements } = getState();
  const actor = renderer.getActors().find((a) => a.getActorId() === elementId);

  if (actor) {
    actor.getProperty().setPointSize(pointSize);

    // Update state with information regarding the pointSize.
    const renderedElementIndex = renderedElements.findIndex(({ id }) => id === actor.getActorId());

    if (renderedElementIndex !== -1) {
      const renderedElement: IThreeDRenderElement = {
        ...renderedElements[renderedElementIndex],
        pointSize,
      };

      setState({
        renderedElements: [
          ...renderedElements.slice(0, renderedElementIndex),
          renderedElement,
          ...renderedElements.slice(renderedElementIndex + 1),
        ],
      });
    }

    renderWindow.render();
    return true;
  }

  return false;
};

const self = {
  _show,
  _hide,
  _setVisibility,
  _getOpacity,
  _colorElement,
  _setLineWidthToElement,
  _getDarkColor,

  highlight,
  unhighlight,
  showElements,
  hideElements,
  hideElement,
  showElement,
  setColorToElement,
  setColorToElements,
  setRepresentationToElements,
  setColorAndRepresentationToElements,
  setBasicColorToActor,
  setGradientToActor,
  updateElementGradient,
  updateElementsGradient,
  getGradientAttributeName,
  getValueColors,
  setScaleToActor,
  setScaleToActors,
  setRepresentation,
  setPointSize,
  setPointSizeToElements,
};
export default self;
