import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core/';
import { INotifications } from './INotifications';
import MikeNotificationItem from './MikeNotificationItem';
import { formatDate } from '../mike-shared-helpers/date';
import { INotification } from './INotification';
import { IMikeTheme } from '../mike-shared-styles';
import { PaletteColor } from '@material-ui/core/styles/createPalette';

interface IProps extends React.HTMLAttributes<HTMLElement> {
  /**
   * Notifications of one day.
   */
  notificationsPerDate?: INotifications;
  /**
   * href.
   */
  href?: string;
  /**
   * To add a placeholder for the first group.
   */
  isFirst: boolean;
  /**
   * Link callback.
   */
  onLinkCallBack?: (arg0: INotification) => void;
  /**
   * Custom render function.
   * To display the content of a notification item.
   */
  renderContent?: (notification: INotification) => {};
  /**
   * Custom render function.
   * To display the title of a notification item.
   */
  renderTitle?: (notification: INotification) => {};
}

const useStyles = makeStyles((theme: IMikeTheme) => {
  const mediumGrey = theme.palette.mediumGrey as PaletteColor; // possibly undefined
  return createStyles({
    notificationDate: {
      marginBottom: theme.spacing(1),
    },
    notificationPerDayInnerContainer: {
      backgroundColor: mediumGrey && mediumGrey.main, // MikeColors.MEDIUMGREY_DEFAULT,
    },
    notification: {
      borderRadius: 4,
      backgroundColor: mediumGrey && mediumGrey.light, // MikeColors.MEDIUMGREY_LIGHT,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    placeHolder: {
      backgroundColor: mediumGrey && mediumGrey.main, // MikeColors.MEDIUMGREY_DEFAULT,
      paddingBottom: theme.spacing(3),
    },
  });
});

/**
 * @name MikeNotificationItemGroup
 * @summary Shows the date of a list of notification items, as well as listing the notification items themselves.
 */
export const MikeNotificationItemGroup: React.FC<IProps> = ({
  notificationsPerDate,
  href,
  isFirst,
  onLinkCallBack,
  renderContent,
  renderTitle,
}: IProps) => {
  const classes = useStyles();

  return (
    <div className={classes.notificationPerDayInnerContainer}>
      {!isFirst && <div className={classes.placeHolder} />}
      <Typography className={classes.notificationDate} variant="body2">
        {formatDate(notificationsPerDate && notificationsPerDate.date)}
      </Typography>
      <div className={classes.notification}>
        {notificationsPerDate &&
          notificationsPerDate.notifications.map((notification: INotification, j) => (
            <MikeNotificationItem
              key={j}
              notification={notification}
              href={href}
              onLinkCallBack={onLinkCallBack}
              renderContent={renderContent}
              renderTitle={renderTitle}
            />
          ))}
      </div>
    </div>
  );
};

export default MikeNotificationItemGroup;
