import { uniq } from 'lodash-es';
import MikeVisualizerUtil from './MikeVisualizerUtil';
import { IValueColor } from './IMikeVisualizerModels';
import MikeVisualizerCosmetic from './MikeVisualizerCosmetic';

const { rendererReady, getDataValues } = MikeVisualizerUtil;
const { getValueColors } = MikeVisualizerCosmetic;

/**
 * Get annotation data and colors for data attributes.
 *
 * @module MikeVisualizerAnnotations
 * @version 1.0.0
 */

/**
 * Get colors for each unique value of the attribute given by elementId and attributeName
 * @param elementId
 * @param attributeName
 */
const getUniqueValuesColors = (
  elementId: string,
  attributeName: string
): Array<IValueColor> | null => {
  if (!rendererReady()) {
    return [];
  }

  if (!elementId || !attributeName) {
    return [];
  }

  const data = getDataValues(elementId, attributeName);
  const values = data ? uniq(data) : [];

  return getValueColors(elementId, values);
};

/**
 * Get colors for equaly distributed values of the attribute given by elementId and attributeName.
 * Will include the min and max value.
 * @param elementId
 * @param attributeName
 * @param range the min and max value
 * @param numberOfColors The number of colors to get.
 */
const getDataRangeColors = (
  elementId: string,
  attributeName: string,
  range: Array<number>,
  numberOfColors = 10
) => {
  if (!rendererReady()) {
    return [];
  }

  if (!elementId || !attributeName || !range || !range.length) {
    return [];
  }

  if (range.length === 1) {
    return getValueColors(elementId, [range[0]]);
  }

  const minValue = range[0];
  const maxValue = range[1];

  if (minValue === maxValue) {
    return getValueColors(elementId, [minValue]);
  }

  const values: Array<number> = [];
  const delta = maxValue - minValue;

  for (let i = 0; i < numberOfColors; i++) {
    const value = minValue + (delta * i) / (numberOfColors - 1);

    values.push(value);
  }

  return getValueColors(elementId, values);
};

const self = {
  getUniqueValuesColors,
  getDataRangeColors,
};

export default self;
