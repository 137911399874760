import { isEqual, noop } from 'lodash-es';
import { defaults } from 'ol/interaction.js';
import Map from 'ol/Map';
import View from 'ol/View';
import { Vector as VectorSource } from 'ol/source.js';
import { Vector as VectorLayer } from 'ol/layer.js';
import { IMapProperties } from '../../IMikeVisualizerModels';
import { MAP_IDS, VIEWER_ZINDEX } from '../../MikeVisualizerConstants';
import { bindInteractorEvents } from '../../vtk-extensions/vtkBasicFullScreenRenderWindow';
import MikeVisualizerStore from '../../store/MikeVisualizerStore';
import MikeVisualizer2DMapUtil from '../MikeVisualizer2DMapUtil';
import MikeVisualizerUtil from '../../MikeVisualizerUtil';
import MikeVisualizer2DMeasureInteractions from './MikeVisualizer2DMeasureInteractions';
import MikeVisualizer2DMeasureUtil from './MikeVisualizer2DMeasureUtil';
import { getOlStylePresets } from '../MikeVisualizer2DDrawConstants';

const { rendererReady } = MikeVisualizerUtil;
const { getState, setState } = MikeVisualizerStore;
const {
  _getViewerProperties,
  _getEpsgString,
  _verifyProjection,
  _zoomAndCenterOpenLayersView,
} = MikeVisualizer2DMapUtil;
const { _removeMeasureMapInteractions } = MikeVisualizer2DMeasureInteractions;
const { _destroyHelpTooltip, _destroyMeasureTooltip } = MikeVisualizer2DMeasureUtil;

let subscriptions: Array<any> = []; // Local interactor subscriptions. These should be managed within this module and cleared upon destroy.

/**
 * Contains methods required for creting a 2d measure map & setting up all default interactions.
 * Setup methods are typically called as part of enabling measurement tools.
 *
 * @note The measure map is on top of all other maps/layers.
 * @note This component is an addon. It is not needed for the MikeVisualizer to function. It can be dynamically imported anywhere in the MikeVisualizer or outside of it.
 *
 * @module MikeVisualize2DMeasureCore
 * @version 1.0.0
 *
 * @internal
 */

/**
 * Moves the measure map to the position (center & zoom) that matches the 3D viewer's bounds (current corners of the canvas).
 * Requires a measure map to be setup.
 * This method won't update the map if measure map properties (bbox, height, width, etc) haven't changed.
 *
 * @private
 */
const _syncMeasureMapTo3DViewerBounds = () => {
  const { measureMapProperties, measureMap } = getState();
  const viewerProperties = _getViewerProperties();

  if (!measureMap) {
    return false;
  }

  if (viewerProperties && !isEqual(viewerProperties, measureMapProperties)) {
    const view = measureMap.getView();

    _zoomAndCenterOpenLayersView(view, viewerProperties as IMapProperties);
    setState({ measureMapProperties: viewerProperties as IMapProperties });
  }

  return true;
};

/**
 * Creates an OpenLayers map instance.
 * Event listeners are also setup for interactions on the 3D viewer & resize.
 * Whenever the 3D viewer is interacted with, the map position syncs accordingly.
 *
 * The listeners `unsubscribe` methods are stored so they can be called when destroying the map.
 *
 * @public
 */
const _getOrSetupOpenLayersMeasureMap = async () => {
  if (!rendererReady()) {
    console.debug('Attempted to setup measure map before the renderer was ready.');
    return false;
  }

  const {
    container: vtkContainer,
    renderWindow,
    fullScreenRenderWindow,
    epsgCode,
    measureMapPromise: existingMeasureMapPromise,
  } = getState();
  const epsgString = _getEpsgString(epsgCode);

  if (existingMeasureMapPromise) {
    return existingMeasureMapPromise;
  }

  const measureMapPromise: Promise<Map> = new Promise(async (resolve) => {
    const mapContainer = document.createElement('div');

    // Verify given projection and try to fetch it if not defined.
    try {
      await _verifyProjection(epsgCode);
    } catch (error) {
      console.error('Failed to setup measure map.', error);
      return false;
    }

    // Create a view for the given projection. The view is what reprojects the tiles.
    const view = new View({
      projection: epsgString,
      center: [0, 0],
      zoom: 5,
  
    });

    // Create a HTMLElement container where OpenLayers will add canvas, controls, etc. The container should be removed when destroyed.
    mapContainer.style.cssText = `
      width: 100%;
      height: 100%;
      z-index: ${VIEWER_ZINDEX.MEASUREMAP};
      opacity: 1;
    `;
    mapContainer.id = MAP_IDS.MEASUREMAP_CONTAINER_ID;
    (vtkContainer as HTMLElement).appendChild(mapContainer);
    const source = new VectorSource({ wrapX: true });
    const vector = new VectorLayer({
      source,
      updateWhileAnimating: false,
      updateWhileInteracting: false,
      // renderMode: 'image', // not a valid option
    });
    vector.setStyle(getOlStylePresets().MEASUREMENT.vectorLayer);

    const map = new Map({
      controls: [],
      layers: [vector],
      target: mapContainer,
      view,
      interactions: defaults({
        altShiftDragRotate: false,
        onFocusOnly: false,
        // constrainResolution: false, // not a valid option
        doubleClickZoom: false,
        keyboard: false,
        mouseWheelZoom: false,
        shiftDragZoom: false,
        dragPan: false,
        pinchRotate: false,
        pinchZoom: false,
        zoomDelta: undefined,
        zoomDuration: undefined,
      }),
    });

    _removeMeasureMapInteractions(map);

    const interactor = renderWindow.getInteractor();
    const defaultSubscriptions = bindInteractorEvents(interactor, mapContainer);

    subscriptions = [
      ...subscriptions,
      interactor.onStartMouseWheel(_syncMeasureMapTo3DViewerBounds),
      interactor.onEndMouseWheel(_syncMeasureMapTo3DViewerBounds),
      interactor.onMouseWheel(_syncMeasureMapTo3DViewerBounds),
      interactor.onPinch(_syncMeasureMapTo3DViewerBounds),
      interactor.onPan(_syncMeasureMapTo3DViewerBounds),
      interactor.onMouseMove(_syncMeasureMapTo3DViewerBounds),
      ...defaultSubscriptions, // Also keep track of default bindings to unsubscribe later
    ];

    setState({ measureMap: map, measureMapProperties: { epsgCode } });

    // Make sure the map is updated when the window is resized.
    fullScreenRenderWindow.setResizeCallback(_syncMeasureMapTo3DViewerBounds);

    // Do an initial sync with the 3D viewer
    self._syncMeasureMapTo3DViewerBounds();

    resolve(map);
  });

  setState({ measureMapPromise });
  return measureMapPromise;
};

/**
 * Removes the open layer measure map and:
 *  - unsubscribes from all map events
 *  - removes the HTMLElement used as a container
 *  - updates state and clears measure map properties
 *
 * @public
 */
const _destroyOpenLayersMeasureMap = () => {
  if (!rendererReady()) {
    console.info('Attempted to destroy measure map before the renderer was ready.');
    return false;
  }

  const { container: vtkContainer, measureMap, renderWindow, fullScreenRenderWindow } = getState();
  const mapContainer = (vtkContainer as HTMLElement).querySelector(
    `#${MAP_IDS.MEASUREMAP_CONTAINER_ID}`
  );

  if (!mapContainer) {
    console.info(
      'Dropped removing measure map because the map container is not in the DOM anymore.'
    );
    return false;
  }

  try {
    subscriptions = subscriptions.filter((subscription) => subscription.unsubscribe());

    /**
     * NB: it's important to re-bind events to the 'real viewer' aka the 3D renderer.
     * Keep in mind that the interactor can only be binded to one container at a time and that has to be either the open layers one while drawing or the 3D renderer otherwise.
     */
    bindInteractorEvents(renderWindow.getInteractor(), fullScreenRenderWindow.getContainer());

    measureMap ? measureMap.setTarget(undefined) : noop();
    (vtkContainer as HTMLElement).removeChild(mapContainer);

    setState({
      measureMapPromise: null,
      measureMapProperties: null,
      measureMap: null,
      measureMapUrl: null,
    });

    return true;
  } catch (e) {
    console.error('Failed to remove measure map.', e);
    return false;
  }
};

/**
 * This method is intended to be called from external modules.
 * For now, it triggers a map change callback, but in the future it might cancel or reset other map properties.
 *
 * @public
 */
const forceOpenLayersMeasureMapUpdate = () => self._syncMeasureMapTo3DViewerBounds();

/**
 * Disables measuring tools, clearing all measures
 */
const disableMeasureTools = () => {
  _destroyHelpTooltip();
  _destroyMeasureTooltip();
  self._destroyOpenLayersMeasureMap();
};

const self = {
  _syncMeasureMapTo3DViewerBounds,
  _getOrSetupOpenLayersMeasureMap,
  _destroyOpenLayersMeasureMap,

  forceOpenLayersMeasureMapUpdate,
  disableMeasureTools,
};

export default self;
