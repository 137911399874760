import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import MikeDatePickerInput from './MikeDatePickerInput';
import 'react-datepicker/dist/react-datepicker.css';
import MikeDatePickerHeader from './MikeDatePickerHeader';
import { Grid } from '@material-ui/core';
import { Portal } from 'react-overlays';
import { addMonths } from 'date-fns';
import './MikeDatePicker.css';
import MikeTooltip from '../mike-tooltip';

interface IProps {
  rangePicker?: boolean;
  hideIcon?: boolean;
  defaultStartDate?: Date;
  defaultEndDate?: Date;
  onChangeStartDate?: (date: Date) => void;
  onChangeEndDate?: (date: Date) => void;
  popperPlacement?: string;
  popperRangePlacement?: string;
  dateFormat?: string;
  minDateStart?: Date | null;
  maxDateStart?: Date | null;
  minDateEnd?: Date | null;
  maxDateEnd?: Date | null;
  showTimeSelect?: boolean;
  timeIntervals?: number;
  tooltipTitles?: {
    startDate?: string;
    endDate?: string;
  };
}

const CalendarContainer = ({ children }) => {
  const el = document.getElementById('root');
  return <Portal container={el}>{children}</Portal>;
};

const RangeContainer = ({ children }) => {
  const el = document.getElementById('root');
  return <Portal container={el}>{children}</Portal>;
};

/**
 * @name MikeDatePicker
 * @summary Allows picking a date or date range.
 */
export const MikeDatePicker = (props: IProps) => {
  const {
    rangePicker,
    hideIcon,
    defaultEndDate,
    defaultStartDate,
    onChangeStartDate,
    onChangeEndDate,
    popperPlacement,
    popperRangePlacement,
    dateFormat,
    minDateStart,
    maxDateStart,
    minDateEnd,
    maxDateEnd,
    showTimeSelect,
    timeIntervals,
    tooltipTitles,
  } = props;
  // Set initial tooltip titles or override with props:
  const ttTitles = {
    startDate: rangePicker ? 'Select start date' : 'Select date',
    endDate: 'Select end date',
    ...tooltipTitles,
  };

  // Initially assign both to prevent linting error: TS undefined
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  useEffect(() => {
    const initialStartDate = defaultStartDate ? defaultStartDate : new Date();
    const initialEndDate = defaultEndDate ? defaultEndDate : new Date();
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);
  }, [defaultStartDate, defaultEndDate]);

  const InputRef = React.forwardRef((props2, ref) => (
    <MikeDatePickerInput innerRef={ref} {...props2} hideIcon={hideIcon} />
  ));

  const handleStartDateChange = (date: Date) => {
    setStartDate(date);
    if (onChangeStartDate) {
      onChangeStartDate(date);
    }
  };

  const handleEndDateChange = (date: Date) => {
    setEndDate(date);
    if (onChangeEndDate) {
      onChangeEndDate(date);
    }
  };

  return (
    <>
      <Grid container spacing={5}>
        <Grid item sm={6} xs={12}>
          <MikeTooltip title={ttTitles.startDate}>
            <DatePicker
              popperContainer={CalendarContainer}
              dateFormat={dateFormat ? dateFormat : 'dd MMM yyyy'}
              renderCustomHeader={(props3) => (
                <MikeDatePickerHeader 
                {...props3} 
                dateChange={handleStartDateChange} 
                minYear={minDateStart ? minDateStart.getFullYear() : undefined} 
                maxYear={maxDateStart ? maxDateStart.getFullYear() : undefined} 
                />
              )}
              minDate={minDateStart}
              maxDate={maxDateStart}
              showMonthDropdown
              showYearDropdown
              showPopperArrow={false}
              showTimeSelect={showTimeSelect}
              timeIntervals={timeIntervals}
              selected={startDate}
              dropdownMode="select"
              onChange={handleStartDateChange}
              customInput={<InputRef />}
              popperPlacement={popperPlacement ? popperPlacement : 'bottom-start'}
            />
          </MikeTooltip>
        </Grid>
        {rangePicker ? (
          <Grid item sm={6} xs={12}>
            <MikeTooltip title={ttTitles.endDate}>
              <DatePicker
                dateFormat={dateFormat ? dateFormat : 'dd MMM yyyy'}
                popperContainer={RangeContainer}
                renderCustomHeader={(props4) => (
                  <MikeDatePickerHeader 
                  {...props4} 
                  dateChange={handleEndDateChange} 
                  minYear={minDateEnd ? minDateEnd.getFullYear() : startDate.getFullYear()} 
                  maxYear={maxDateEnd ? maxDateEnd.getFullYear() : undefined} 
                  />
                )}
                showMonthDropdown
                showYearDropdown
                showPopperArrow={false}
                showTimeSelect={showTimeSelect}
                timeIntervals={timeIntervals}
                selected={endDate && endDate >= startDate ? endDate : startDate}
                dropdownMode="select"
                minDate={minDateEnd ? minDateEnd : startDate}
                maxDate={maxDateEnd ? maxDateEnd : addMonths(startDate, 120)}
                onChange={handleEndDateChange}
                customInput={<InputRef />}
                popperPlacement={popperRangePlacement ? popperRangePlacement : 'bottom-start'}
              />
            </MikeTooltip>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default MikeDatePicker;
