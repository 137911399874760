import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import { IMikeTheme } from '../mike-shared-styles';
import { ReactComponent as NotificationAlert } from 'icons/NotificationAlert';
import { ReactComponent as NotificationBell } from 'icons/NotificationBell';

interface IProps extends WithStyles<typeof style> {
  /**
   * To set the visibility of the blue dot from parent.
   */
  dotInvisible: boolean;
  /**
   * Click callback.
   */
  onClickCallBack?: () => void;
}

const style = (theme: IMikeTheme) => ({
  button: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
    postion: 'relative',
    minHeight: 60,
    '&:hover': {
      backgroundColor: theme.palette.text.disabled,
    },
    marginTop: '-4px',
    cursor: 'pointer',
  },
});

/**
 * @name MikeNotificationButton
 * @summary The notification bottom, showing if new notifications are available via a red dot.
 */
export class MikeNotificationButton extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const { onClickCallBack, classes, dotInvisible } = this.props;

    return (
      <ButtonBase className={classes.button} onClick={onClickCallBack}>
        {dotInvisible ? <NotificationBell /> : <NotificationAlert />}
      </ButtonBase>
    );
  }
}

export default withStyles(style)(MikeNotificationButton);
