import { FeatureCollection, GeometryCollection } from 'geojson';
import Map from 'ol/Map';
import OlFeature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import MikeVisualizerStore from '../../store/MikeVisualizerStore';
import MikeVisualizer2DMapUtil from '../MikeVisualizer2DMapUtil';
import MikeVisualizerUtil from '../../MikeVisualizerUtil';
import MikeVisualizer2DEvents from './MikeVisualizer2DEvents';

const { geometryCollectionToFeatureCollection } = MikeVisualizerUtil;
const { getState, setState } = MikeVisualizerStore;
const { _getVectorLayerSource, _getOlFeatureId, _setOlFeatureId } = MikeVisualizer2DMapUtil;
const { _emitChanges } = MikeVisualizer2DEvents;

/**
 * Contains methods that allows getting, setting, clearing data while for the 2d draw vector layer.
 *
 * @module MikeVisualizer2DDrawIO
 * @version 1.0.0
 */

/**
 * Clears any features present on the draw map's vector layer.
 * This does not remove the draw vector layer.
 *
 * @param map Map to clear vector layer from.
 *
 * @private
 */
const _clearDrawVectorLayerGeojson = (map: Map) => {
  const vectorSource = _getVectorLayerSource(map);

  setState({ drawMapUpdatesPaused: true });
  vectorSource.clear(true);
  setState({ drawMapUpdatesPaused: false });

  // NB: because updates were paused, now it is necessary to emit a single update event.
  _emitChanges(vectorSource)();
};

/**
 * Sets the currently drawn content to the provided geojson.
 * It removes any previously drawn geojson, if it exists.
 *
 * @param geojson
 */
const setDrawVectorLayerGeojson = async (geojson: FeatureCollection<any, any>) => {
  const { drawMap } = getState();

  if (drawMap) {
    self._clearDrawVectorLayerGeojson(drawMap);
  }

  return self.appendDrawVectorLayerGeojson(geojson);
};

/**
 * Appends the provided geojson to the currently drawn content.
 *
 * @param geojson
 */
const appendDrawVectorLayerGeojson = (
  geojson: FeatureCollection<any, any> | GeometryCollection
): Array<OlFeature> => {
  const { drawMap } = getState();

  const setDrawMapGeojson = (drawMapInstance) => {
    // Updates are paused to not trigger callbacks for each added feature.
    // In the future, we might decide to also pause open layers rendering until all features are added (if possible).
    setState({ drawMapUpdatesPaused: true });

    const vectorSource = _getVectorLayerSource(drawMapInstance);
    const features = new GeoJSON()
      .readFeatures(
        (geojson as FeatureCollection<any, any>).features
          ? geojson
          : geometryCollectionToFeatureCollection(geojson as GeometryCollection)
      )
      .map((feature: OlFeature) => {
        if (!_getOlFeatureId(feature)) {
          // Set a unique id to each feature. This prevents features with no id from being appended; when that happens, open layers just replaces the previous feature with no id.
          _setOlFeatureId(feature);
        }

        return feature;
      });

    vectorSource.addFeatures(features);

    setState({ drawMapUpdatesPaused: false });

    // NB: because updates were paused, now it is necessary to emit a single update event.
    _emitChanges(vectorSource)();
    return features;
  };

  if (!drawMap) {
    console.info('Aborted appending draw vector layer geojson, no draw map found');
    return [];
  }

  return setDrawMapGeojson(drawMap);
};

/**
 * Clears any drawn features from the draw map if it exists.
 * Also clears features on interaction layers.
 */
const clearDrawnVectorLayerData = () => {
  const { drawMap } = getState();
  if (drawMap) {
    const interactions = [...drawMap.getInteractions().getArray()];
    // Hacky `any` fix of errors after @types/ol install:
    interactions.forEach(
      (interaction: any) => interaction.getFeatures && interaction.getFeatures().clear(true)
    );
    self._clearDrawVectorLayerGeojson(drawMap);
  }
};

const self = {
  _clearDrawVectorLayerGeojson,

  setDrawVectorLayerGeojson,
  appendDrawVectorLayerGeojson,
  clearDrawnVectorLayerData,
};

export default self;
