import { EDataArrayDataTypes } from './MikeVisualizerConstants';
import { IRepresentation } from './models/IRepresentation';

// todo dan: should split this up
export enum EViewerModes {
  TWO_D = 'viewer-2D',
  THREE_D = 'viewer-3D',
}

export const VIEWER_MODES = {
  TWO_D: EViewerModes.TWO_D,
  THREE_D: EViewerModes.THREE_D,
};

export interface IViewerBounds extends Array<number> {
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
  zMin: number;
  zMax: number;
}

export interface IDrawnDataGradientSettings {
  gradientColorMap?: IColorMap | IAnnotatedColorMap | ISelectAllColorMap; // an alternative to gradientPreset. If a color map is present, it will be used as the first option instead of gradientPreset.
  gradientPreset?: string; // A vtk.js color preset. See available presets here: https://github.com/Kitware/vtk-js/blob/master/Sources/Rendering/Core/ColorTransferFunction/ColorMaps.json
  numberOfSignificantDigits?: number; // The number of significant digits used in rounding
  numberOfLegends?: number; // The number of legends in the case data is not categorical
}

export interface IElementFieldData {
  [index: number]: {
    id: string;
    data: Array<number>;
  };
}

export interface IMapProperties {
  bbox?: Array<number>;
  center?: Array<number>;
  width: number;
  height: number;
  epsgCode?: number;
}

export interface IRenderElement {
  id: string;
  opacity: number;
}

export interface ITwoDRenderElement extends IRenderElement {
  edgeColor: string;
  surfaceColor: string;
}

export interface IThreeDRenderElement extends IRenderElement {
  edgeColor: Array<number>;
  surfaceColor: Array<number>;
  representation: IRepresentation;
  dataArrays: Array<IElementDataArray>;
  pointSize?: number;
  gradientSettings?: IDrawnDataGradientSettings;
  gradientAttributeName?: string;
  gradientApplied?: boolean;
  fieldData: Array<IElementFieldData>;
  zAttributeName?: string;
}

export interface IElementDataArray {
  id: string;
  type: string; // CellData or PointData
  dataType?: EDataArrayDataTypes;
  range?: Array<number>;
  numberOfValues?: number;
}

export interface IValueColor {
  value: number;
  rgba: Array<number>;
}

export interface IVtkColorObject {
  edge: Array<number>;
  surface: Array<number>;
}

export interface IColorMap {
  ColorSpace: string;
  Name: string;
  RGBPoints: Array<number>;
  colorMappingRange?: Array<number>; // defines the value range to be colored. Values outside this will use aboveRangeColor/belowRangeColor if provided
  aboveRangeColor?: Array<number>; // defines the rgba color for values above range. This is not a property of ColorMaps in vtk.
  belowRangeColor?: Array<number>; // defines the rgba color for values below range. This is not a property of ColorMaps in vtk.
}

export interface IColorRange {
  colorMappingRange?: Array<number>; // defines the value range to be colored. Values outside this will use aboveRangeColor/belowRangeColor if provided
  aboveRangeColor?: Array<number>; // defines the rgba color for values above range. This is not a property of ColorMaps in vtk.
  belowRangeColor?: Array<number>; // defines the rgba color for values below range. This is not a property of ColorMaps in vtk.
}

export interface IAnnotatedColorMap {
  ShowIndexedColorActiveValues: number;
  IndexedColors: Array<number>;
  Annotations: Array<number | string>;
  Name: string;
}

export interface ISelectAllColorMap {
  Name: string;
  selectColor: Array<number>;
}

export interface IVtkAttributes {
  type: string;
  byte_order: string;
  header_type: string;
  compressor: string;
  [key: string]: string;
}

export interface IVtkDataObject {
  getName: () => string;
  getData: () => Array<number | string>;
  getRange: () => Array<number>;
}

export interface IExtent extends Array<number> {
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
  zMin: number;
  zMax: number;
}

export interface IOrigin extends Array<number> {
  originX: number;
  originY: number;
  originZ: number;
}

export interface ISpacing extends Array<number> {
  spacingX: number;
  spacingY: number;
  spacingZ: number;
}

export interface IOBJTextureMap {
  imgName: string;
  imgUrl: string;
}
