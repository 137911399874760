import React, { Suspense } from 'react';

import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import MoreVert from '@material-ui/icons/MoreVert';
import Skeleton from '@material-ui/lab/Skeleton';
import MikeTooltip from '../mike-tooltip/MikeTooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { TableContainer, Paper } from '@material-ui/core';
import { formatDate } from '../mike-shared-helpers/date';

import './style.css';

interface IProps {
  rows: Array<any>; // TODO: Add real type iso any
  filter: string;
  loading: boolean;
  columns: any; // TODO: Add real type iso any
  config: any; // TODO: Add real type iso any
  actions?: any; // TODO: Add real type iso any
  onSelect?: (row: any) => void; // TODO: Add real type iso any
  maxHeight?: string;
  onSort?: (param: any) => void; // TODO: Add real type iso any
  sortByField: any; // TODO: Add real type iso any
  isSorting: boolean;
}

interface ISortIconProps {
  id: string;
  label: string;
}

const useStyles = makeStyles({
  iconButton: {
    borderRadius: '50px',
  },
  iconCell: {
    display: 'inline-flex',
  },
  actionCell: {
    width: '40px',
  },
  actionCellHead: {
    width: '40px',
    background: 'white',
  },
  fistColumn: {
    marginLeft: '32px',
  },
  headCell: {
    background: 'white',
  },
  cell: {
    maxWidth: '400px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'table-cell',
  },
});



const _MikeTable: React.FC<IProps> = ({
  rows,
  onSelect,
  loading,
  columns,
  actions,
  config,
  maxHeight,
  onSort,
  sortByField,
  isSorting,
}) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const maxTableHeight = maxHeight ? maxHeight : '500px';

  React.useEffect(() => {
    setPage(0);
  }, [rows]);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortClick = (id: string, colType: string) => {
    if (onSort) {
      onSort({
        field: id,
        dir: sortByField.dir === 'asc' ? 'desc' : 'asc',
        type: colType,
      });
    }
  };

  const SortIcon: React.FC<ISortIconProps> = ({ id, label }) => {
    return (
      <MikeTooltip title={'Sort for ' + label}>
        <IconButton size="small" className={classes.iconButton}>
          {id === sortByField.field ? (
            <ArrowUpward
              className={
                sortByField.dir === 'asc'
                  ? 'c-ProjectsTable-SortIcon-Up'
                  : 'c-ProjectsTable-SortIcon-Down'
              }
              fontSize="small"
            />
          ) : (
            <ArrowUpward className={'c-ProjectsTable-SortIcon-Disabled'} fontSize="small" />
          )}
        </IconButton>
      </MikeTooltip>
    );
  };

  const TableHeader: React.FC = () => {
    return (
      <TableHead>
        <TableRow>
          {columns.map((row: any, index: number) => {
            return (
              <TableCell
                className={classes.headCell}
                id={row.id}
                key={row.id}
                onClick={() => {
                  if (row.sortable) {
                    handleSortClick(row.id, row.type);
                  }
                }}
                align={row.align ? row.align : 'left'}
              >
                <b style={{ marginLeft: index === 0 ? 32 : 0 }}>{row.label}</b>
                {row.sortable ? <SortIcon id={row.id} label={row.label} /> : null}
              </TableCell>
            );
          })}
          {actions ? <TableCell className={classes.actionCellHead} align="center" /> : null}
        </TableRow>
      </TableHead>
    );
  };

  const TableBodySkeleton: React.FC = () => {
    const skeletonRows = [1, 2, 3, 4, 5, 6];
    const skeletonHead = [...Array(columns.length).keys()];
    return (
      <TableBody>
        {skeletonRows.map((ind: any) => (
          <TableRow key={ind}>
            {skeletonHead.map((_head, index) => {
              return (
                <TableCell key={index} align="left">
                  <Skeleton height={38} />
                </TableCell>
              );
            })}
            <TableCell align="left" className={classes.actionCell}>
              <Skeleton height={38} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  };

  const Actions = ({ row }) => {
    return (
      <PopupState variant="popover" popupId="action-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <MoreVert {...bindTrigger(popupState)} />
            <Menu
              {...bindMenu(popupState)}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              MenuListProps={{
                style: {
                  padding: 0,
                },
              }}
            >
              {actions.map((a: any, index: number) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={(e) => {
                      e.preventDefault();
                      popupState.close();
                      a.callBack(row);
                    }}
                  >
                    {a.icon ? <ActionIcon name={a.icon} /> : ''}
                    {a.render ? a.render(row) : a.name}
                  </MenuItem>
                );
              })}
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    );
  };

  const lazyLoadIconByName = ({ name }) => {
    return React.lazy(() =>
      import('icons/index').then((module) => {
        return {
          default: module.default[name],
        };
      })
    );
  };

  const ActionIcon = (name) => {
    const Icon = lazyLoadIconByName(name);
    return (
      <>
        <Suspense fallback="">
          <Icon />
        </Suspense>
      </>
    );
  };

  const MikeTableBody: React.FC = () => {
    const colIds = columns.map((col: any) => {
      return col.id;
    });
    return (
      <TableBody>
        {(rowsPerPage > 0
          ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : rows
        ).map((row: any, index: number) => (
          <TableRow
            key={index}
            onClick={() => {
              if (onSelect) {
                onSelect(row);
              }
            }}
          >
            {columns.map((col: any, i: number) => {
              const cellValue =
                col.type === 'date' ? formatDate(row[colIds[i]], col.showTime) : row[colIds[i]];
              return (
                <TableCell
                  className={col.className ? col.className : classes.cell}
                  key={i}
                  onClick={() => {
                    if (col.onClick) {
                      col.onClick(row);
                    }
                  }}
                  align={col.align ? col.align : 'left'}
                >
                  <div style={{ marginLeft: i === 0 ? 32 : 0, display: 'flex' }}>
                    {col.render ? col.render(row, cellValue) : cellValue}
                  </div>
                </TableCell>
              );
            })}
            {actions ? (
              <TableCell align="center" className={classes.actionCell}>
                <span style={{ marginRight: 32 }}>
                  <IconButton className={classes.iconButton}>
                    <Actions row={row} />
                  </IconButton>
                </span>
              </TableCell>
            ) : null}
          </TableRow>
        ))}
      </TableBody>
    );
  };

  return (
    <Paper>
      <TableContainer style={{ maxHeight: `${maxTableHeight}`, overflow: 'auto' }}>
        <Table stickyHeader aria-label="simple table">
          <TableHeader />
          {loading || isSorting ? <TableBodySkeleton /> : <MikeTableBody />}
        </Table>
      </TableContainer>
      {config.pagination && (
        <TablePagination
          rowsPerPageOptions={[10, 25, { label: 'All', value: -1 }]}
          colSpan={3}
          component="div"
          count={rows && rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};

export default _MikeTable;
