import { FeatureCollection } from 'geojson';

import { Vector as VectorSource } from 'ol/source.js';
import { Vector as VectorLayer } from 'ol/layer.js';
import { Style } from 'ol/style';
import Map from 'ol/Map';
import GeoJSON from 'ol/format/GeoJSON';

import MikeVisualizer2DDataCore from './MikeVisualizer2DDataCore';
import MikeVisualizerStore from '../../store/MikeVisualizerStore';
import MikeVisualizer2DMapUtil from '../MikeVisualizer2DMapUtil';

import { ITwoDRenderElement } from '../../IMikeVisualizerModels';
import { getConfiguration } from '../../MikeVisualizerConfiguration';
import BaseLayer from 'ol/layer/Base';

const { getState, setState } = MikeVisualizerStore;
const { _getOrSetupOpenLayersDataMap } = MikeVisualizer2DDataCore;
const { _makeDefaultVectorStyle } = MikeVisualizer2DMapUtil;

/**
 * This module allows 2d, geojson data to be added, updated or removed.
 *
 * @module MikeVisualizer2DIO
 * @version 1.0.0
 */

/**
 * Parses geojson and adds it to the data map, creating a new vector layer with configured styles.
 *
 * @param geojson
 * @param elementId
 * @param surfaceColor
 * @param edgeColor
 * @param opacity
 * @param openLayersStyle
 */
const append2DData = async (
  geojson: FeatureCollection<any, any>,
  elementId: string,
  surfaceColor = getConfiguration().drawColors.background,
  edgeColor = getConfiguration().drawColors.bright,
  opacity = 1,
  openLayersStyle?: Style
) => {
  const dataMap = (await _getOrSetupOpenLayersDataMap()) as Map;

  try {
    // Setup vector layer & style
    const source = new VectorSource({
      wrapX: true,
      features: new GeoJSON().readFeatures(geojson),
    });
    const vector = new VectorLayer({
      source,
      updateWhileAnimating: false,
      updateWhileInteracting: false,
      // renderMode: 'image', // Not a valid option for VectorLayer
      opacity,
    });
    const style = openLayersStyle || _makeDefaultVectorStyle(surfaceColor, edgeColor);
    vector.setStyle(style);
    // TODO: joel; A nasty hack was made here and it should be fixed! The hack is to add a non-existing property
    // `elementId` to a third party library (OpenLayers' VectorLayer instance) while other core functionality e.g. in
    // `delete2DData` and `update2DData` rely on it.
    // vector.elementId = elementId; // Old statement which doesn't work with strict types.
    ((vector as unknown) as IMikeOlBaseLayer).elementId = elementId;

    dataMap.addLayer(vector);

    const renderedElement: ITwoDRenderElement = {
      id: elementId,
      edgeColor,
      surfaceColor,
      opacity,
    };

    const { rendered2DElements } = getState();

    setState({
      rendered2DElements: [...rendered2DElements, renderedElement],
    });

    return vector;
  } catch (error) {
    console.error('Failed to append 2d data', error);
    return false;
  }
};

/**
 * Updates data, looking up its vector layer by id and replacing it.
 *
 * @param geojson
 * @param elementId
 * @param surfaceColor
 * @param edgeColor
 * @param opacity
 * @param openLayersStyle
 */
const update2DData = async (
  geojson: FeatureCollection<any, any>,
  elementId: string,
  surfaceColor = getConfiguration().drawColors.background,
  edgeColor = getConfiguration().drawColors.bright,
  opacity = 1,
  openLayersStyle?: Style
) => {
  try {
    await self.delete2DData(elementId);
    return self.append2DData(geojson, elementId, surfaceColor, edgeColor, opacity, openLayersStyle);
  } catch (error) {
    console.error('Failed to update 2d data', error);
    return false;
  }
};

/**
 * Deletes data from the map, clearing the associated vector layer.
 *
 * @param elementId
 */
const delete2DData = async (elementId: string) => {
  const { rendered2DElements } = getState();
  const dataMap = (await _getOrSetupOpenLayersDataMap()) as Map;

  try {
    dataMap.getLayers().forEach((layer) => {
      const layr = layer as IMikeOlBaseLayer;
      if (layr && layr.elementId === elementId) {
        dataMap.removeLayer(layer);
      }
    });

    setState({
      rendered2DElements: rendered2DElements.filter(({ id }) => id !== elementId),
    });

    return true;
  } catch (error) {
    console.error('Failed to delete 2d data', error);
    return false;
  }
};

/**
 * Get data of an item.
 *
 * @param elementId
 */
const get2DData = async (elementId: string): Promise<FeatureCollection<any, any>> => {
  const dataMap = (await _getOrSetupOpenLayersDataMap()) as Map;
  let elementLayer;

  dataMap.getLayers().forEach((layer) => {
    const layr = layer as IMikeOlBaseLayer;
    if (layr && layr.elementId === elementId) {
      elementLayer = layer;
    }
  });

  if (elementLayer) {
    return {
      type: 'FeatureCollection',
      features: new GeoJSON().writeFeaturesObject(elementLayer.getSource().getFeatures()).features,
    };
  }

  return {
    type: 'FeatureCollection',
    features: [],
  };
};

/**
 * Get all present data as a merged feature collection.
 */
const getAll2DData = async (): Promise<FeatureCollection<any, any>> => {
  const { rendered2DElements } = getState();
  const geojson = {
    type: 'FeatureCollection',
    features: [],
  } as FeatureCollection<any, any>;

  for (const element of rendered2DElements) {
    const data = await get2DData(element.id);
    geojson.features = [...geojson.features, ...data.features];
  }

  return geojson;
};

const self = {
  _makeDefaultVectorStyle,

  append2DData,
  update2DData,
  delete2DData,
  get2DData,
  getAll2DData,
};

export default self;

// These 2 interfaces are part of a temporary TypeScript fix for a nasty hack introduced in `append2DData`,
// where a new property is added to objects from a 3rd party library:
export interface IMikeOlBaseLayer extends BaseLayer {
  elementId: string;
}
export interface IMikeOlVectorLayer extends VectorLayer {
  elementId: string;
}
