import React from 'react';
import { css } from 'emotion';
import theme from '../mike-shared-styles/mikeSharedTheme';
import MIKE_COLORS from '../mike-shared-styles/mike-colors';

const PanelBottomActionsStyle = css`
  background: ${theme.palette.background.paper};
  border-top: 2px solid ${MIKE_COLORS.DARKGREY_DEFAULT};
  margin-top: -1px;
  padding: ${theme.spacing(3)}px ${theme.spacing(2)}px;
  position: sticky;
  bottom: 0;

  z-index: var(--panel-header-zindex);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type PanelBottomActionsProps = {
  children?: React.ReactNode;
};

/**
 * @name MikeStickyPanelBottomActions
 * @summary Panels can have optional bottom actions, usually containing buttons.
 * The bottom actions are 'sticky to the bottom'.
 *
 * @param props
 */
export const MikeStickyPanelBottomActions = (props: PanelBottomActionsProps) => {
  return <div className={PanelBottomActionsStyle}> {props.children}</div>;
};
