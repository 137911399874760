import MikeVisualizerStore from '../../store/MikeVisualizerStore';
import MikeVisualizer2DMapUtil from '../MikeVisualizer2DMapUtil';

const { getState } = MikeVisualizerStore;
const { _getVectorLayerSource } = MikeVisualizer2DMapUtil;

/**
 * Allows controlling measure data.
 *
 * @module MikeVisualize2DMeasureIO
 * @version 1.0.0
 */

/**
 * Clears any measurements made so far.
 *
 * @public
 */
const clearMeasurements = () => {
  const { measureMap } = getState();
  if (measureMap) {
    const vectorSource = _getVectorLayerSource(measureMap);
    const interactions = [...measureMap.getInteractions().getArray()];
    // Hacky `any` fix of errors after @types/ol install:
    interactions.forEach(
      (interaction: any) => interaction.getFeatures && interaction.getFeatures().clear(true)
    );
    vectorSource.clear(true);
  }
};

const self = {
  clearMeasurements,
};

export default self;
