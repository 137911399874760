import { replace } from 'lodash-es';

export interface IBaseMapConfiguration {
  id: string;
  title: string;
  url: string;
  attributions: Array<string> | null;
}

export enum EBaseMapIds {
  MAPBOX_STREET = 'mapbox-street',
  MAPBOX_SATELLITE = 'mapbox-satellite',
  MAPTILER_STREET = 'maptiler-street',
  MAPTILER_SATELLITE = 'maptiler-satellite',
  MAPTILER_HYBRID = 'maptiler-hybrid',
  OSM = 'open-street-map',
  NONE = 'no-basemap',
}

/**
 * A plain object is also exported with the base map ids in order to be used as literal values.
 * The EBaseMapIds enum can only be used for type definitions.
 */
export const BASE_MAP_IDS = {
  MAPBOX_STREET: EBaseMapIds.MAPBOX_STREET,
  MAPBOX_SATELLITE: EBaseMapIds.MAPBOX_SATELLITE,
  MAPTILER_STREET: EBaseMapIds.MAPTILER_STREET,
  MAPTILER_SATELLITE: EBaseMapIds.MAPTILER_SATELLITE,
  MAPTILER_HYBRID: EBaseMapIds.MAPTILER_HYBRID,
  OSM: EBaseMapIds.OSM,
  NONE: EBaseMapIds.NONE,
};

// Attributions
export const MAPTILER_COPYRIGHT =
  '&#169; ' + '<a href="https://maptiler.com/copyright" target="_blank">MapTiler</a> ';

export const MAPBOX_COPYRIGHT =
  '&#169; ' + '<a href="https://www.mapbox.com/about/maps" target="_blank">Mapbox</a> ';

export const MAPBOX_FEEDBACK =
  '&#169; ' + '<a href="https://apps.mapbox.com/feedback" target="_blank">Improve this map</a> ';

// todo hevo 'contributors' outside link as in OpenLayers by default - or inside?
export const OSM_COPYRIGHT =
  '&#169; ' +
  '<a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> ' +
  'contributors.';

export const MAPBOX_ATTRIBUTIONS = [MAPBOX_COPYRIGHT, OSM_COPYRIGHT, MAPBOX_FEEDBACK];
export const MAPTILER_ATTRIBUTIONS = [MAPTILER_COPYRIGHT, OSM_COPYRIGHT];
export const OSM_ATTRIBUTIONS = [OSM_COPYRIGHT];

/**
 * Configuration for basemaps from different sources.
 */
const ACCESS_KEY = '{ACCESS_KEY}';
const BASE_MAP_CONFIGURATIONS: { [index: string]: IBaseMapConfiguration } = {
  'mapbox-street': {
    id: BASE_MAP_IDS.MAPBOX_STREET,
    title: 'Mapbox street',
    url: `https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/256/{z}/{x}/{y}?access_token=${ACCESS_KEY}`,
    attributions: MAPBOX_ATTRIBUTIONS,
  },

  'mapbox-satellite': {
    id: BASE_MAP_IDS.MAPBOX_SATELLITE,
    title: 'Mapbox satellite',
    url: `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/256/{z}/{x}/{y}?access_token=${ACCESS_KEY}`,
    attributions: MAPBOX_ATTRIBUTIONS,
  },

  'maptiler-street': {
    id: BASE_MAP_IDS.MAPTILER_STREET,
    title: 'Maptiler street',
    url: `https://api.maptiler.com/maps/topo/256/{z}/{x}/{y}.png?key=${ACCESS_KEY}`, // todo hevo 256 or not??? FloodScreener does notr have it
    attributions: MAPTILER_ATTRIBUTIONS,
  },

  'maptiler-satellite': {
    id: BASE_MAP_IDS.MAPTILER_SATELLITE,
    title: 'Maptiler satellite',
    url: `https://api.maptiler.com/tiles/satellite/{z}/{x}/{y}.jpg?key=${ACCESS_KEY}`, 
    attributions: MAPTILER_ATTRIBUTIONS,
  },

  'maptiler-hybrid': {
    id: BASE_MAP_IDS.MAPTILER_HYBRID,
    title: 'Maptiler hybrid',
    url: `https://api.maptiler.com/maps/hybrid/256/{z}/{x}/{y}.jpg?key=${ACCESS_KEY}`, // todo hevo 256 or not??? DataLink+FloodCcreener does notr have it
    attributions: MAPTILER_ATTRIBUTIONS,
  },

  'open-street-map': {
    id: BASE_MAP_IDS.OSM,
    title: 'Open street map',
    url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
    attributions: OSM_ATTRIBUTIONS,
  },

  'no-basemap': {
    id: BASE_MAP_IDS.NONE,
    title: 'No basemap',
    url: '',
    attributions: null,
  },
};

/**
 * Gets the configuration of the base map given by the id.
 *
 * @param id
 * @param accessKey The access token or key for the base map source
 *
 */
export const getBaseMapConfiguration = (
  id: EBaseMapIds,
  accessKey?: string
): IBaseMapConfiguration => {
  const configuration = BASE_MAP_CONFIGURATIONS[id];

  if (!configuration) {
    console.warn('Unknown base map id', id);
  }

  const { url } = configuration;

  if (!accessKey && url && url.includes(ACCESS_KEY)) {
    console.warn('Base map requires an access token/key', id);
  }

  const urlWithAccesskey = accessKey ? replace(url, ACCESS_KEY, accessKey) : url;

  return {
    ...configuration,
    url: urlWithAccesskey,
  };
};

const self = {
  getBaseMapConfiguration,
};

export default self;
