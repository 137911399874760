import { throttle, isEqual, noop } from 'lodash-es';

import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import { Attribution } from 'ol/control';

import proj4 from 'proj4';
import { register } from 'ol/proj/proj4.js';

import MikeVisualizerStore from '../store/MikeVisualizerStore';
import MikeVisualizerUtil from '../MikeVisualizerUtil';
import MikeVisualizer2DMapUtil from './MikeVisualizer2DMapUtil';
import { COMMON_PROJECTIONS } from './MikeVisualizerProjections';
import { MAP_IDS, VIEWER_ZINDEX } from '../MikeVisualizerConstants';
import { IMapProperties } from '../IMikeVisualizerModels';
import { getEmitters } from '../MikeVisualizerEvents';
import { Graticule } from 'ol';

const {
  emitBaseMapLayerChanged,
  emitBaseMapDestroyed,
  emitBaseMapProjectionNotSupported,
} = getEmitters();

const { getState, setState } = MikeVisualizerStore;
const { rendererReady } = MikeVisualizerUtil;
const {
  _getEpsgString,
  _verifyProjection,
  _getViewerProperties,
  _zoomAndCenterOpenLayersView,
} = MikeVisualizer2DMapUtil;

let subscriptions: Array<{ unsubscribe: () => void }> = [];

// Register known projections with proj4js.
COMMON_PROJECTIONS.forEach(([epsg, definition]) => proj4.defs(epsg, definition));
register(proj4);

/**
 * Implementation of 'traditional' 2D slippy maps by syncing OpenLayers with the 3D viewer.
 * Using maps requires the viewer to be locked in 2D (rotation, tilt, etc doesn't work).
 * Maps are always displayed underneath the 3D vtk.js viewer.
 *
 * @note This component is an addon. It is not needed for the MikeVisualizer to function. It can be dynamically imported anywhere in the MikeVisualizer or outside of it.
 *
 * @module MikeVisualizer2DBaseMaps
 * @version 1.0.0
 */

/**
 * Moves the base map to the position (center & zoom) that matches the 3D viewer's bounds (current corners of the canvas).
 * Requires a basemap to be setup.
 * This method won't update the map if base map properties (bbox, height, width, etc) haven't changed.
 *
 * @private
 */
const _syncBaseMapTo3DViewerBounds = () => {
  const { baseMapProperties, baseMap } = getState();
  const viewerProperties = _getViewerProperties();
  if (!baseMap) {
    return false;
  }
  if (viewerProperties && !isEqual(viewerProperties, baseMapProperties)) {
    const view = baseMap.getView();
    _zoomAndCenterOpenLayersView(view, viewerProperties as IMapProperties);
    setState({ baseMapProperties: viewerProperties as IMapProperties });
  }
  return true;
};

/**
 * Creates an OpenLayers map for the provided url.
 * Event listeners are also setup for interactions on the 3D viewer & resize.
 * Whenever the 3D viewer is interacted with, the map position syncs accordingly.
 *
 * The listeners `unsubscribe` methods are stored so they can be called when destroying the map.
 *
 * @param url The basemap url to use.
 * @param attributions The attributions for the basemap
 *
 * @public
 */
const setupOpenLayersBaseMap = async (url: string, attributions?: Array<string>) => {
  if (!rendererReady()) {
    console.debug('Attempted to setup base map before the renderer was ready.');
    return null;
  }

  const {
    container: vtkContainer,
    renderWindow,
    fullScreenRenderWindow,
    baseMapUrl,
    baseMapAttributions,
    epsgCode,
    baseMapPromise: existingBaseMapPromise,
  } = getState();
  const epsgString = _getEpsgString(epsgCode);

  if (existingBaseMapPromise) {
    return existingBaseMapPromise;
  }

  // If no url is defined, use the one from the state
  const mapUrl = url || baseMapUrl;
  const mapAttributions = attributions || baseMapAttributions || [];

  const baseMapPromise: Promise<Map> = new Promise(async (resolve, reject) => {
    const mapContainer = document.createElement('div');

    // todo hevo ?????????????
    // const attribution = document.createElement('div');

    // Verify given projection and try to fetch it if not defined.
    try {
      await _verifyProjection(epsgCode);
    } catch (error) {
      console.error('Failed to setup base map.', error);
      return false;
    }

    // Create a view for the given projection. The view is what reprojects the tiles.
    const view = new View({
      projection: epsgString,
      center: [0, 0],
      zoom: 5,
    });

    // todo hevo: handle watermark depending on source
    // Add attribution to the map element.
    // attribution.innerHTML = `
    //   <a href="http://mapbox.com/about/maps" class='mapbox-wordmark' target="_blank">Mapbox</a>
    //   <div class="mapbox-attribution-container">
    //     <a href="https://www.mapbox.com/about/maps/">© Mapbox | </a>
    //     <a href="http://www.openstreetmap.org/copyright">© OpenStreetMap | </a>
    //     <a href="https://www.mapbox.com/map-feedback/" target="_blank"><strong>Improve this map</strong></a>
    //   </div>`;
    // attribution.style.cssText = `
    //   position: absolute;
    //   bottom: 0;
    //   right: 0;
    //   width: 100%;
    // `;
    // mapContainer.appendChild(attribution);

    const attribution = new Attribution({
      collapsed: false,
      collapsible: false,
      target: mapContainer,
    });

    // Create a HTMLElement container where OpenLayers will add canvas, controls, etc. The container should be removed when destroyed.
    mapContainer.style.cssText = `
      width: 100%;
      height: 100%;
      z-index: ${VIEWER_ZINDEX.BASEMAP};
      opacity: 1;
    `;
    mapContainer.id = MAP_IDS.BASEMAP_CONTAINER_ID;
    (vtkContainer as HTMLElement).appendChild(mapContainer);

    const map = new Map({
      controls: [attribution],
      layers: mapUrl
        ? [
            new TileLayer({
              preload: Infinity,
              source: new XYZ({
                url, // why is this not the same mapUrl as above?
                wrapX: true,
                attributions: mapAttributions,
              }),
            }),
          ]
        : [],
      target: mapContainer,
      view,
    });

    setState({ baseMap: map, baseMapUrl: mapUrl, baseMapAttributions: mapAttributions });

    const mapChange = throttle(self._syncBaseMapTo3DViewerBounds, 15); // Throtlling is required especially for panning, where the 3D viewer spams the event listeners with callbacks.
    const interactor = renderWindow.getInteractor();
    subscriptions = [
      ...subscriptions,
      interactor.onStartMouseWheel(mapChange),
      interactor.onEndMouseWheel(mapChange),
      interactor.onMouseWheel(mapChange),
      interactor.onPinch(mapChange),
      interactor.onPan(mapChange),
      interactor.onMouseMove(mapChange),
    ];

    // Make sure the map is updated when the window is resized.
    fullScreenRenderWindow.setResizeCallback(mapChange);

    // Do an initial sync with the 3D viewer
    self._syncBaseMapTo3DViewerBounds();

    // Check if the set projection is actually supported. The only way to do that as far as I (Dan) can tell is to verify that the extent is set.
    // This is not destructive; everything else functions as expected, only the basemap is not shown.
    if (isEqual(map.getView().calculateExtent(map.getSize()), [NaN, NaN, NaN, NaN])) {
      emitBaseMapProjectionNotSupported();
      destroyOpenLayersBaseMap();
      reject(`Base map doesn't work for SRS/projection "${epsgString}"`);
    }

    emitBaseMapLayerChanged(map);
    resolve(map);
  });

  setState({ baseMapPromise });
  return baseMapPromise;
};

/**
 * Removes the open layer basemap and:
 *  - unsubscribes from all map events
 *  - removes the HTMLElement used as a container
 *  - updates state and clears base map properties
 *
 * @public
 */
const destroyOpenLayersBaseMap = () => {
  if (!rendererReady()) {
    console.info('Attempted to destroy base map before the renderer was ready.');
    return false;
  }

  const { container: vtkContainer, baseMap } = getState();
  const mapContainer = (vtkContainer as HTMLElement).querySelector(
    `#${MAP_IDS.BASEMAP_CONTAINER_ID}`
  );

  if (!mapContainer) {
    console.info('Dropped removing base map because the map container is not in the DOM anymore.');
    return false;
  }

  try {
    subscriptions = subscriptions.filter((subscription) => subscription.unsubscribe());
    if (!baseMap) {
      return false;
    }
    baseMap.setTarget(undefined);
    (vtkContainer as HTMLElement).removeChild(mapContainer);

    setState({
      baseMapPromise: null,
      baseMapProperties: null,
      baseMap: null,
      baseMapUrl: null,
      baseMapAttributions: [],
    }); // Always set state after interactor reset!

    emitBaseMapDestroyed();

    return true;
  } catch (e) {
    console.error('Failed to remove base map.', e);
    return false;
  }
};

/**
 * Updates the basemap to use a different url.
 * This method is intended to be used for switching between i.e. satellite & street view without re-setting up the map.
 *
 * @param url The basemap url to update the map to.
 * @param attributions The attributions for the basemap
 *
 * @public
 */
const updateOpenLayersBaseMap = (url: string, attributions?: Array<string>) => {
  if (!rendererReady()) {
    console.debug('Attempted to setup base map before the renderer was ready.');
    return false;
  }

  // Save the baseMapUrl and attributions in state, so it can be used if setting up the map happens after this
  setState({ baseMapUrl: url, baseMapAttributions: attributions });

  const { baseMapPromise } = getState();
  const runBaseMapUpdate = () => {
    const { baseMap } = getState();
    if (!baseMap) {
      console.error('No base map');
      return;
    }
    baseMap.getLayers().forEach((layer) => {
      !(layer instanceof Graticule) ? baseMap.removeLayer(layer) : noop();
    });
    const xyzTileLayer = new TileLayer({
      source: new XYZ({
        url,
        wrapX: true,
        attributions,
      }),
    });

    baseMap.addLayer(xyzTileLayer);
    emitBaseMapLayerChanged(baseMap);
    return true;
  };

  if (baseMapPromise) {
    return baseMapPromise.then(runBaseMapUpdate);
  }

  // If the map url is updated before a basemap has been created, then setup the base map at this point.
  const setupBaseMapPromise = setupOpenLayersBaseMap(url, attributions);
  setState({ baseMapPromise: setupBaseMapPromise });
  return setupBaseMapPromise;
};

/**
 * This method is intended to be called from external modules.
 * For now, it triggers a map change callback, but in the future it might cancel or reset other map properties.
 *
 * @public
 */
const forceOpenLayersBaseMapUpdate = () => self._syncBaseMapTo3DViewerBounds();

const self = {
  _syncBaseMapTo3DViewerBounds,

  setupOpenLayersBaseMap,
  destroyOpenLayersBaseMap,
  updateOpenLayersBaseMap,
  forceOpenLayersBaseMapUpdate,
};

export default self;
