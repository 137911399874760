import MikeVisualizerStore from './store/MikeVisualizerStore';
import MikeVisualizerUtil from './MikeVisualizerUtil';
import MikeVisualizerCosmetic from './MikeVisualizerCosmetic';
import { IVtkColorObject } from './IMikeVisualizerModels';
import { getConfiguration } from './MikeVisualizerConfiguration';

const { getState, setState } = MikeVisualizerStore;
const { rendererReady } = MikeVisualizerUtil;
const { _colorElement } = MikeVisualizerCosmetic;

/**
 * Contains 3D selection related logic.
 * 'Selected' is a state one or more geometries can have. It is meant to mimic typical selections in CAD, 3D or 2D drawing software => it means actions would be performed on that selection.
 * In practical terms, users can select multiple geometries to i.e. delete items or to perform a step as part of a flow (i.e. select geometries and apply an operation, like: merge, simplify, create group, etc).
 *
 * For now, selection can only be controlled programatically and not through pressing on geometries.
 * In the future, selection should be possible by interacting directly with the visualization; ctrl + press would select one item, ctrl + shift + press would append / remove items from selection.
 *
 * @module MikeVisualizerSelect
 * @version 1.0.0
 */

/**
 * Selects an actor by id. For now, this only sets color.
 *
 * @param elementId
 *
 * @private
 */
const _select = (elementId: string) => {
  const { colors } = getConfiguration();

  return self._setSelectedItemColor(elementId, false, colors.select);
};

/**
 * Deselects an actor by id. For now, this only sets color to the highlight value.
 *
 * @param elementId
 *
 * @private
 */
const _deselect = (elementId: string) => {
  const { renderedElements } = getState();
  const renderedElement = renderedElements.find(({ id }) => id === elementId);

  if (!renderedElement) {
    return false;
  }

  return _colorElement(elementId, renderedElement.edgeColor, renderedElement.surfaceColor);
};

/**
 * Sets a selection color for a given element id and optionally moves it to top.
 *
 * @param elementId The id of the element to set selection color for.
 * @param moveToTop Flag to move actor on top of everything else.
 * @param [highlightColor] The selected + highlighted color.
 * @param [selectColor] The selected color.
 *
 * @private
 */
const _setSelectedItemColor = (
  elementId: string,
  moveToTop: boolean,
  selectColor?: IVtkColorObject
) => {
  if (!rendererReady()) {
    return false;
  }

  const { renderer } = getState();
  const actor = renderer.getActors().find((a) => a.getActorId() === elementId);

  if (actor) {
    if (moveToTop) {
      renderer.moveEnhancedActorToTop(elementId);
    }

    return _colorElement(
      elementId,
      selectColor ? selectColor.edge : undefined,
      selectColor ? selectColor.surface : undefined
    );
  }

  return false;
};

/**
 * Marks a list of elements as selected.
 * Clears previously selected elements.
 *
 * @param elementIds
 *
 * @public
 */
const selectElements = (elementIds: Array<string>) => {
  if (!rendererReady()) {
    return false;
  }

  const { selectedElementIds, renderWindow } = getState();

  const elementsToDeselect = selectedElementIds.filter((pId) => !elementIds.includes(pId));
  setState({
    selectedElementIds: elementIds,
  });

  elementsToDeselect.forEach(self._deselect);
  elementIds.forEach(self._select);
  renderWindow.render();
  return true;
};

/**
 * Marks a list of elements as deselected.
 *
 * @param elementIds
 *
 * @public
 */
const deselectElements = (elementIds: Array<string>) => {
  if (!rendererReady()) {
    return false;
  }

  const { selectedElementIds, renderWindow } = getState();

  setState({
    selectedElementIds: selectedElementIds.filter((pId) => !elementIds.includes(pId)),
  });

  elementIds.forEach(self._deselect);
  renderWindow.render();
  return true;
};

const self = {
  _setSelectedItemColor,
  _select,
  _deselect,

  selectElements,
  deselectElements,
};
export default self;
