import MikeVisualizerStore from './store/MikeVisualizerStore';
import MikeVisualizerUtil from './MikeVisualizerUtil';
import MikeVisualizerCosmetic from './MikeVisualizerCosmetic';
import MikeVisualizerViewManager from './MikeVisualizerViewManager';
import { IViewerBounds } from './IMikeVisualizerModels';
import { _getEventEmitter, EVENTS } from './MikeVisualizerEvents';

const { getState, setState, resetState } = MikeVisualizerStore;
const { rendererReady } = MikeVisualizerUtil;
const { setScaleToActors } = MikeVisualizerCosmetic;
const { _makeCamera } = MikeVisualizerViewManager;

/**
 * Core functionality of the geometry viewer.
 * - exposes methods to add/remove actors
 * - uses MikeVisualizerCosmetic to modify appearance
 *
 * @module MikeVisualizerCore
 * @version 1.0.0
 */

/**
 * Returns currently stored initial bounds.
 *
 * @public
 */
const getInitialBounds = () => {
  return getState().initialBounds;
};

/**
 * Sets camera initial bounds to accommodate the entire viewable data.
 * Bounds will only be set once, otherwise there's a risk of making the camera jump around whenever data is updated (quite bad UX).
 *
 * @param bounds Array of bounds as x1, x2, y1, y2, z1, z2, i.e. [-2.237, -2.198, 52.036, 52.103, 0, 0].
 *
 * @public
 */
const setInitialBounds = (bounds: IViewerBounds) => {
  if (!rendererReady()) {
    return false;
  }

  const { initialBounds } = getState();

  if (initialBounds) {
    console.info('Initial bounds already set, setting initial bounds again has no effect.');

    return false;
  }

  setState({ initialBounds: bounds });
  _makeCamera(bounds);

  return true;
};

/**
 * Sets viewer scale and rescales all actors.
 *
 * @param viewerZScale The z-scale to apply to each actor in the visualization.
 *
 * @public
 */
const setViewerZScale = (viewerZScale: number) => {
  const { renderer, renderWindow } = getState();

  if (!rendererReady()) {
    return false;
  }

  try {
    setScaleToActors(renderer.getActors(), 1, 1, viewerZScale);
    setState({ viewerZScale });

    // Recompute bounds to make sure exaggerated features fit.
    renderer.resetCameraClippingRange();
    renderWindow.render();
    return true;
  } catch (e) {
    console.error('Failed to set viewer scale', e);
    return false;
  }
};

/**
 * Destroys the vtp viewer & cleans up.
 * TODO: are there more things to remove / unsubscribe from?
 */
const destroy = () => {
  const { renderWindow, renderer, container } = getState();

  // Clears events & unsubscribes. No matter how many methods are subscribed, they all get cleared.
  const EventEmitter = _getEventEmitter();
  Object.keys(EVENTS).forEach((eventName) => EventEmitter.off(eventName));

  // Clean up instances and container. It's unclear how much it helps, but hopefully it removes some of the vtk.js internal event listeneres.
  if (renderer) {
    renderer.removeAllViewProps();
    renderer.removeAllLights();
    renderer.delete();
  }

  if (renderWindow) {
    renderWindow.getInteractor().unbindEvents();
    renderWindow.getInteractor().delete();
    renderWindow.delete();
  }

  // Clears container children for next visit.
  if (container) {
    container.innerHTML = '';
    container.remove();
  }

  resetState();
};

const self = {
  getInitialBounds,
  setInitialBounds,
  setViewerZScale,
  destroy,
};
export default self;
