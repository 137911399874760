import * as mapshaper from 'mapshaper-es/www/mapshaper.js';
import { ParsedArgs } from 'minimist';
import { FeatureCollection, Feature } from 'geojson';
import { oneLine } from 'common-tags';
import MikeVisualizerUtil from './MikeVisualizerUtil';
import MikeVisualizer2DMapUtil from './2d/MikeVisualizer2DMapUtil';

const { geometryCollectionToFeatureCollection } = MikeVisualizerUtil;
const { getEpsgCodeFromPrjFile } = MikeVisualizer2DMapUtil;

/**
 * This module exposes mapshaper.js methods.
 * It can be operations (clip, simplify, etc) or queries (get features, geometry information, etc).
 *
 * @note This component is an addon. It is not needed for the MikeVisualizer to function. It can be dynamically imported anywhere in the MikeVisualizer or outside of it.
 *
 * @module MikeVisualizerMapShaper
 * @version 1.0.0
 */

const FILENAMES = {
  TARGET: 'target.geojson',
  SOURCE: 'source.geojson',
  OUTPUT: 'output.geojson',
};

const defaultGeojsonOutput: FeatureCollection<any, any> = {
  type: 'FeatureCollection',
  features: [],
};

/**
 * Gets the extensions from a file name (the thing after the '.').
 *
 * @param name
 */
const getFileNameExtension = (name: string) => name.slice(name.lastIndexOf('.') + 1);

/**
 * Clips geometries by a polygon.
 * NB: clipping by multi polygons untested.
 *
 * @param targetGeojson Clipping target.
 * @param sourceGeojson Clip by this polygon.
 *
 * @public
 */
const clip = (
  targetGeojson: FeatureCollection<any, any>,
  sourceGeojson: FeatureCollection<any, any>
) =>
  self
    ._runCommand('clip', { _: ['remove-slivers'] }, targetGeojson, sourceGeojson)
    .catch((error) => {
      console.error('Failed to clip', error);
      throw error;
    });



    const erase = (
      targetGeojson: FeatureCollection<any, any>,
      sourceGeojson: FeatureCollection<any, any>
    ) =>
      self
        ._runCommand('erase', { _: ['remove-slivers'] }, targetGeojson, sourceGeojson)
        .catch((error) => {
          console.error('Failed to clip', error);
          throw error;
        });
/**
 * Simplify the geometry of polygon and polyline features.
 *
 * @param targetGeojson Simplify target.
 * @param percentage Percentage of removable points to retain, e.g. 10%.
 *
 * @public
 */
const simplify = (targetGeojson: FeatureCollection<any, any>, percentage: number) =>
  self
    ._runCommand('simplify', { _: [], percentage: `${percentage}%` }, targetGeojson)
    .catch((error) => {
      console.error('Failed to simplify', error);
      throw error;
    });

/**
 * Dissolve the geometry, merging adjacent polygons (repairs overlaps and gaps).
 *
 * @param targetGeojson Dissolve target.
 * @param minGapArea Smaller gaps than this are filled (default is small)
 *
 * @public
 */
const dissolve = (targetGeojson: FeatureCollection<any, any>, minGapArea: number) =>
  self
    ._runCommand('dissolve2', { _: [], 'min-gap-area': minGapArea }, targetGeojson)
    .catch((error) => {
      console.error('Failed to dissolve', error);
      throw error;
    });

/**
 * Clean the geometry, repairing overlaps and small gaps in polygon layers.
 *
 * @param targetGeojson Clean target.
 * @param minGapArea Smaller gaps than this are filled (default is small)
 * @param snapInterval Snapping distance in source units (default is tiny)
 *
 * @public
 */
const clean = (
  targetGeojson: FeatureCollection<any, any>,
  minGapArea: number,
  snapInterval: number
) =>
  self
    ._runCommand(
      'clean',
      { _: [], 'min-gap-area': minGapArea, 'snap-interval': snapInterval },
      targetGeojson
    )
    .catch((error) => {
      console.error('Failed to clean', error);
      throw error;
    });

/**
 * Reproject using mapshaper's proj4 UI.
 *
 * @param targetGeojson
 * @param from source CRS using a Proj4 definition or alias
 * @param to destination CRS using a Proj4 definition or alias
 */
const proj = (targetGeojson: FeatureCollection<any, any>, from: string, to: string) =>
  self._runCommand('proj', { _: [], crs: to, from }, targetGeojson).catch((error) => {
    console.error('Failed to reproject', error);
    throw error;
  });

/**
 * Divide multi-part features into single-part features
 *
 * @param targetGeojson
 */
const explode = (targetGeojson: FeatureCollection<any, any>) =>
  self
    ._runCommand('explode', { _: [] }, targetGeojson)
    .then((featureCollection) => {
      return {
        ...(featureCollection as FeatureCollection<any, any>),
        features: (featureCollection as FeatureCollection<any, any>).features.map((feature) => {
          return {
            ...feature,
            id: `expld-${Math.random()}`,
          };
        }),
      };
    })
    .catch((error) => {
      console.error('Failed to explode', error);
      throw error;
    });

/**
 * Exports the provided geojson to shp files.
 *
 * @param targetGeojson
 * @param layerName Name of each shape file <name>.shp, <name>.dbf, etc
 * @param downloadAsZip Create & make the browser download a zip of the shapefiles.
 */
const exportShpFromGeojson = (
  targetGeojson: FeatureCollection<any, any>,
  layerName: string,
  downloadAsZip: boolean
): Promise<Array<{ name: string; data: string }>> =>
  new Promise((resolve, reject) => {
    const fileName = layerName;
    const outputName = FILENAMES.OUTPUT.slice(0, FILENAMES.OUTPUT.lastIndexOf('.'));

    const exportCommand = oneLine`
    -i ${FILENAMES.TARGET}
    encoding=utf-8
    -o ${FILENAMES.OUTPUT}
    format=shapefile
    `;

    mapshaper.applyCommands(
      exportCommand,
      {
        [FILENAMES.TARGET]: targetGeojson,
      },
      async (err, output) => {
        if (err) {
          mapshaper.internal.logArgs([`${err.toString()} \n`]);
          console.error(err);
          reject(new Error('Failed to run command 😵'));
          return false;
        }

        try {
          const data = [
            {
              name: `${fileName}.shp`,
              data: output[`${outputName}.shp`],
            },
            {
              name: `${fileName}.shx`,
              data: output[`${outputName}.shx`],
            },
            {
              name: `${fileName}.dbf`,
              data: output[`${outputName}.dbf`],
            },
            // Remove the .prj file as it is corrupt, see user story https://dhigroup.visualstudio.com/MIKE/_workitems/edit/28843
            // Also see github discussion with MapShaper developer: https://github.com/mbloch/mapshaper/issues/430
          ];

          if (downloadAsZip) {
            const JSZipModule = await import('jszip');
            const JSZip = JSZipModule.default;
            const zip = new JSZip();
            for (const item of data) {
              zip.file(item.name, item.data, { binary: true });
            }
            const zipContent = await zip.generateAsync({ type: 'blob' });
            const fileSaverModule = await import('file-saver');
            const { saveAs } = fileSaverModule;
            saveAs(zipContent, `${layerName}.zip`);
          }
          resolve(data);
          return data;
        } catch (error) {
          console.error('Failed to export shapefile', error);
          reject(error.toString()); // eslint-disable-line
          return false;
        }
      }
    );
  });

/**
 * Exports the provided shp files to geojson.
 *
 * @param shapefiles
 * @param layerName Name of the geojson file. Don't include extension.
 * @param downloadAsZip Create & make the browser download a zip of the geojson.
 */
const exportGeojsonFromShp = (
  shapefiles: Array<{ name: string; data: ArrayBuffer }>,
  layerName: string,
  downloadAsZip: boolean
): Promise<
  | {
      featureCollection: FeatureCollection<any, any>;
      epsgCode: number;
      crs: string;
    }
  | string
> =>
  new Promise((resolve, reject) => {
    if (!shapefiles.length) {
      reject(new Error('No shapefiles provided.'));
    }

    const exportCommand = oneLine`
      -i '${shapefiles.reduce((acc, cur) => {
        if (self.getFileNameExtension(cur.name) === 'shp') {
          return cur.name;
        }

        return acc;
      }, '')}'
      -o ${FILENAMES.OUTPUT}
      format=geojson
      encoding=utf-8
    `;

    const shapefilesObject = shapefiles
      .filter(({ name }) => self.getFileNameExtension(name) !== 'cpg') // Filter out cpg's. They seem to crash the conversion.
      .reduce((acc: { [name: string]: ArrayBuffer }, cur: { name: string; data: ArrayBuffer }) => {
        return {
          ...acc,
          [cur.name]: cur.data,
        };
      }, {});

    mapshaper.applyCommands(exportCommand, shapefilesObject, async (err, output) => {
      if (err) {
        mapshaper.internal.logArgs([`${err.toString()} \n`]);
        console.error(err);
        reject(new Error('Failed to run command 😵'));
        return false;
      }

      try {
        const decoder = new TextDecoder('utf-8');
        const outputLength = Object.keys(output).length;

        if (outputLength === 0) {
          return resolve(output);
        }

        // Output can be > 1.
        const data = {
          // TODO: dan: this could be an util
          ...defaultGeojsonOutput,
          features: Object.keys(output).reduce((acc: Array<Feature<any, any>>, key) => {
            const encodedGeojson = output[key];
            const decodedGeojson = JSON.parse(decoder.decode(encodedGeojson));
            const features = decodedGeojson.features || [];
            const geometries = decodedGeojson.geometries
              ? geometryCollectionToFeatureCollection(decodedGeojson).features
              : [];

            return [...acc, ...features, ...geometries];
          }, []),
        };

        if (downloadAsZip) {
          const JSZipModule = await import('jszip');
          const JSZip = JSZipModule.default;
          const zip = new JSZip();

          zip.file(`${layerName}.geojson`, JSON.stringify(data));

          const zipContent = await zip.generateAsync({ type: 'blob' });
          const fileSaverModule = await import('file-saver');
          const { saveAs } = fileSaverModule;
          saveAs(zipContent, `${layerName}.zip`);
        }

        const prjFile = shapefiles.find(
          ({ name }) => self.getFileNameExtension(name) === 'prj'
        ) as { name: string; data: ArrayBuffer };
        const { epsgCode, crs } = await getEpsgCodeFromPrjFile(
          prjFile ? prjFile.data : new ArrayBuffer(0)
        );

        const result = {
          featureCollection: data,
          epsgCode,
          crs,
        };
        resolve(result);
        return result;
      } catch (error) {
        console.error('Failed to export geojson', error);
        reject(error.toString()); // eslint-disable-line
        return false;
      }
    });
  });

/**
 * Runs a generic mapshaper command, trying to return the result geojson.
 * Not all commands return geojson, i.e. 'info' returns a text summary.
 *
 * @param commandName The command to run, i.e. clip, merge, simplify, etc
 * @param args The arguments of the command. See mapshaper help, some commands have quite a few args.
 * @param targetGeojson The geojson to run the command on.
 * @param [sourceGeojson] Sometimes, commands require a second geojson object (i.e. clip) to run.
 *
 * @private
 */
const _runCommand = (
  commandName: string,
  args: ParsedArgs,
  targetGeojson: FeatureCollection<any, any>,
  sourceGeojson?: FeatureCollection<any, any>
): Promise<FeatureCollection<any, any> | string> =>
  new Promise((resolve, reject) => {
    const cmd = self._makeCommand(commandName, args, Boolean(sourceGeojson));
    console.debug(`
      Mapshaper command issued:
      ${cmd}
      ${FILENAMES.TARGET}: ${Boolean(targetGeojson)}
      ${FILENAMES.SOURCE}: ${Boolean(sourceGeojson)}
    `);

    mapshaper.applyCommands(
      cmd,
      {
        [FILENAMES.TARGET]: targetGeojson,
        [FILENAMES.SOURCE]: sourceGeojson,
      },
      async (err, output) => {
        if (err) {
          mapshaper.internal.logArgs([`${err.toString()} \n`]);
          reject(new Error('Failed to run command 😵'));
          return false;
        }

        try {
          const decoder = new TextDecoder('utf-8');
          const outputLength = Object.keys(output).length;

          if (outputLength === 0) {
            console.debug('Mapshaper output', output);
            return resolve(output);
          }

          // Output can be > 1. Many operations would produce that, i.e. clip.
          return resolve({
            // TODO: dan: this could be an util
            ...defaultGeojsonOutput,
            features: Object.keys(output).reduce((acc: Array<Feature<any, any>>, key) => {
              const encodedGeojson = output[key];
              const decodedGeojson = JSON.parse(decoder.decode(encodedGeojson));
              const features = decodedGeojson.features || [];
              const geometries = decodedGeojson.geometries
                ? geometryCollectionToFeatureCollection(decodedGeojson).features
                : [];

              return [...acc, ...features, ...geometries];
            }, []),
          });
        } catch (error) {
          console.error('failed to get geojson', error);
          reject(error.toString()); // eslint-disable-line
          return false;
        }
      }
    );
  });

/**
 * Given a command name & arguments, this generates a command string that can be ran by mapshaper's `applyCommands` function.
 *
 * @param commandName
 * @param args
 * @param shouldIncludeSource If the command requires a source geojson object, it needs to be included in the command as `source=<name>` in order for mapshaper to apply it.
 */
const _makeCommand = (
  commandName: string,
  args: ParsedArgs,
  shouldIncludeSource: boolean
) => oneLine`
  -i ${FILENAMES.TARGET}
  -${commandName}
  ${shouldIncludeSource ? `source=${FILENAMES.SOURCE}` : ''}
  ${args._.join(' ')}
  ${Object.keys(args)
    .filter((key) => key !== '_')
    .map((key) => `${key}='${args[key]}'`)
    .join(' ')}
  -o ${FILENAMES.OUTPUT}
`;

const self = {
  _runCommand,
  _makeCommand,
  getFileNameExtension,
  clip,
  erase,
  simplify,
  dissolve,
  clean,
  proj,
  explode,
  exportShpFromGeojson,
  exportGeojsonFromShp,
};
export default self;
