import Map from 'ol/Map';
import { Style } from 'ol/style';
import { Coordinate } from 'ol/coordinate';
import Polygon from 'ol/geom/Polygon';
import LineString from 'ol/geom/LineString';

import MikeVisualizer2DMeasureUtil from './MikeVisualizer2DMeasureUtil';
import MikeVisualizer2DMeasureCore from './MikeVisualizer2DMeasureCore';
import MikeVisualizer2DMeasureInteractions from './MikeVisualizer2DMeasureInteractions';

const { _enableMeasureInteraction } = MikeVisualizer2DMeasureInteractions;
const { _getOrSetupOpenLayersMeasureMap } = MikeVisualizer2DMeasureCore;
const {
  _updateHelpTooltip,
  _updateMeasureTooltip,
  _createStaticMeasureTooltip,
  _setMeasurementUnit,
  getFormattedArea,
} = MikeVisualizer2DMeasureUtil;

/**
 * Allows enabling/disabling area measurements.
 *
 * @module MikeVisualize2DAreaMeasure
 * @version 1.0.0
 */
const TOOLTIP_WHILE_DRAWING = 'Press to add more points. Double-press to complete.';
const TOOLTIP_BEFORE_DRAWING = 'Press to start measuring area';

/**
 * Enable 2D area measurements via polygon drawing.
 * This will take focus of the visualizer until disabled.
 *
 * @param [drawInteractionStyle]
 * @param [vectorLayerStyle]
 * @param [clearFeaturesOnStart] Remove previous measurements when starting to make a new measurement.
 */
const enable2DAreaMeasure = async (
  drawInteractionStyle?: (feature) => Array<Style>,
  vectorLayerStyle?: (feature) => Array<Style>,
  clearFeaturesOnStart?: boolean
) => {
  const measureMap = (await _getOrSetupOpenLayersMeasureMap()) as Map;

  const onUpdateHelpTooltip = (position: Coordinate, isSomethingSketched: boolean) => {
    const tooltipMessage = isSomethingSketched ? TOOLTIP_WHILE_DRAWING : TOOLTIP_BEFORE_DRAWING;

    _updateHelpTooltip(tooltipMessage, position);
  };

  const onUpdateMeasureTooltip = (geometry: LineString | Polygon) => {
    const geom = geometry as Polygon;
    const tooltipCoord = geom.getInteriorPoint().getCoordinates();
    _updateMeasureTooltip(getFormattedArea(geom), tooltipCoord);
  };

  const onCreateStaticMeasureTooltip = (geometry: LineString | Polygon) => {
    const geom = geometry as Polygon;
    _createStaticMeasureTooltip(getFormattedArea(geom), geometry.getLastCoordinate());
  };

  try {
    await _enableMeasureInteraction(
      measureMap,
      'Polygon',
      drawInteractionStyle,
      vectorLayerStyle,
      clearFeaturesOnStart,
      onUpdateHelpTooltip,
      onUpdateMeasureTooltip,
      onCreateStaticMeasureTooltip
    );

    return true;
  } catch (error) {
    console.error('Failed to enable 2d area measure', error);
    return false;
  }
};

const setAreaMeasurementUnit = (unit: string) => {
  _setMeasurementUnit(unit)
}

const self = {
  setAreaMeasurementUnit,
  enable2DAreaMeasure,
};

export default self;
