import { Select } from 'ol/interaction.js';
import { singleClick } from 'ol/events/condition.js';
import { Style } from 'ol/style';
import Feature from 'ol/Feature';

import { getEmitters } from '../../MikeVisualizerEvents';
import MikeVisualizerStore from '../../store/MikeVisualizerStore';
import MikeVisualizerDraw2DCore from '../draw/MikeVisualizer2DDrawCore';
import MikeVisualizer2DMapUtil from '../MikeVisualizer2DMapUtil';
import MikeVisualizer2DInteractions from './MikeVisualizer2DInteractions';
import { getOlStylePresets } from '../MikeVisualizer2DDrawConstants';
import { getConfiguration } from '../../MikeVisualizerConfiguration';

const { getState, setState } = MikeVisualizerStore;
const {
  _removeAllMapInteractions,
  _getVectorLayer,
  _getVectorLayerSource,
  _getOlFeatureId,
} = MikeVisualizer2DMapUtil;
const { _getOrSetupDrawMap } = MikeVisualizerDraw2DCore;
const { _enableDragBoxInteraction } = MikeVisualizer2DInteractions;
/**
 * Contains methods that allows enabling/disabling 2d inspection of drawn features.
 *
 * @module MikeVisualizer2DInspectionTools
 * @version 1.0.0
 */

/**
 * Enables inspection while in 2d draw.
 * This does not inspect data from the 2d data map (yet); it can only inspect drawn data.
 * When pressing anywhere on the map, it reports properties of a feature (if any are available at the pressed position).
 * It is possible to select multiple features, either by shift-select or with a drag box.
 *
 * Callbacks to property changes can be registered via the `onDrawnDataInspectionChanged` event.
 *
 * @param [vectorLayerStyle] A function returning an array of ol/style definitions, to be used as the style for the drawn item(s) when they are part of the vector layer.
 * @param [selectInteractionStyle] A function returning an array of ol/style definitions, to be used as the style for selected items.
 */
const enable2DDrawInspection = async (
  vectorLayerStyle: (feature) => Array<Style> = getOlStylePresets().DEFAULT.vectorLayer,
  selectInteractionStyle: (feature) => Array<Style> = getOlStylePresets().INFO_SELECTION
    .selectInteraction
) => {
  const drawMap = await _getOrSetupDrawMap();

  if (drawMap) {
    _removeAllMapInteractions(drawMap);

    // Set vector layer style
    const vectorLayer = _getVectorLayer(drawMap);
    vectorLayer.setStyle(vectorLayerStyle);

    // Setup select interaction
    const selectInteraction = new Select({
      style: selectInteractionStyle,
      hitTolerance: getConfiguration().ol.tolerance,
      condition: singleClick,
      // toggleCondition: never, // This effectively disables multi-selection with shift + click
    });
    drawMap.addInteraction(selectInteraction);

    // Listen to add or remove events on the selected features array.
    // Metadata regarding these features is emitted so it can be displayed by a subscriber if needed to be shown in the UI.
    const selectedFeaturesReference = selectInteraction.getFeatures();
    selectedFeaturesReference.on('add', (event) => {
      if (event.element) {
        const metadata = event.element.getProperties();

        if (metadata) {
          // If metadata is present, update the feature's properties in the global state.
          const { geometry: _omitGeometry, id, ...properties } = metadata;

          const { emitDrawMapInspectionChanged } = getEmitters();
          const { drawnMapInspectionSelectedFeatureProperties } = getState();
          const nextInspectionProperties = [
            { id, properties },
            ...drawnMapInspectionSelectedFeatureProperties.filter(
              (item) => item.id !== metadata.id
            ),
          ];

          setState({ drawnMapInspectionSelectedFeatureProperties: nextInspectionProperties });
          emitDrawMapInspectionChanged(nextInspectionProperties);
        }
      }
    });

    selectedFeaturesReference.on('remove', (event) => {
      if (event.element) {
        const metadata = event.element.getProperties();

        if (metadata) {
          const { emitDrawMapInspectionChanged } = getEmitters();
          const { drawnMapInspectionSelectedFeatureProperties } = getState();
          const nextInspectionProperties = drawnMapInspectionSelectedFeatureProperties.filter(
            ({ id }) => id !== metadata.id
          );

          setState({ drawnMapInspectionSelectedFeatureProperties: nextInspectionProperties });
          emitDrawMapInspectionChanged(nextInspectionProperties);
        }
      }
    });

    _enableDragBoxInteraction(drawMap, selectInteraction);
    setState({ drawMapInspectionSelectInteraction: selectInteraction });
  }
};

/**
 * Disables the 2d inspection tool.
 */
const disable2DInspection = () => {
  const { drawMap } = getState();

  if (drawMap) {
    setState({
      drawMapInspectionSelectInteraction: undefined,
      drawnMapInspectionSelectedFeatureProperties: [],
    });
    _removeAllMapInteractions(drawMap);
  }
};

/**
 * Selects the drawn features matching the provided feature ids to 'inspected', effectively selecting them.
 * Replaces previously inspected items.
 *
 * @param vectorLayerStyle
 */
const set2DInspectedFeatures = (featureIds: Array<string>): boolean => {
  const { drawMapInspectionSelectInteraction, drawMap } = getState();

  if (!drawMap || !drawMapInspectionSelectInteraction) {
    return false;
  }

  try {
    const vectorSource = _getVectorLayerSource(drawMap);
    const drawnFeatures = vectorSource.getFeatures();
    const inspectedFeatures = [] as Array<Feature>;

    for (const feature of drawnFeatures) {
      const featureId = _getOlFeatureId(feature);
      if (featureIds.indexOf(featureId) !== -1) {
        inspectedFeatures.push(feature);
      }
    }

    // Remove current selections
    const selectedFeaturesRef = drawMapInspectionSelectInteraction.getFeatures();
    selectedFeaturesRef.clear();

    // Add matching selections
    inspectedFeatures.forEach((feature) => selectedFeaturesRef.push(feature));

    return true;
  } catch (error) {
    console.error('Failed to set 2d inspected features', error);
    return false;
  }
};

const self = {
  enable2DDrawInspection,
  disable2DInspection,
  set2DInspectedFeatures,
};

export default self;
