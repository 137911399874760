import * as React from 'react';
import MikeSvgIcon from '../mike-icon/MikeSvgIcon';
import { withStyles, WithStyles } from '@material-ui/core';

interface IProps extends WithStyles<typeof style> {
  loggedIn: boolean;
  hrefLink: string;
}

const style = {
  button: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
    minHeight: 60,
    '&:hover': {
      backgroundColor: '#DBE4E9',
    },
    marginTop: '-4px',
  },
};

/**
 * @name MikeMyappsButton
 * @summary A button that makes it possible to see their apps.
 */
export class MikeMyappsButton extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const { loggedIn, hrefLink, classes } = this.props;

    if (loggedIn === true) {
      return (
        <a className={classes.button} target="_blank" href={hrefLink}>
          <MikeSvgIcon name={'home'} />
        </a>
      );
    } else {
      return null;
    }
  }
}

export default withStyles(style)(MikeMyappsButton);
