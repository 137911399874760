import vtkOrientationMarkerWidget from 'vtk.js/Sources/Interaction/Widgets/OrientationMarkerWidget';
import vtkInteractiveOrientationWidget from 'vtk.js/Sources/Widgets/Widgets3D/InteractiveOrientationWidget';
import vtkAxesActor from 'vtk.js/Sources/Rendering/Core/AxesActor';
import vtkMath from 'vtk.js/Sources/Common/Core/Math';

import MikeVisualizerStore from './store/MikeVisualizerStore';
import MikeVisualizerUtil from './MikeVisualizerUtil';

const { getState } = MikeVisualizerStore;
const { rendererReady } = MikeVisualizerUtil;

/**
 * Enables / disables navigation tools
 *
 * @module MikeVisualizerNav
 * @version 1.0.0
 */

/**
 * Add navigation cube & axes arrows.
 * By pressing on the face of the cube, the camera will change so the main visualization reflects the cube.
 *
 * TODO: currently the cube does not match the position of the axes.
 *
 * @public
 */
const enableOrientationBox = () => {
  if (!rendererReady()) {
    return false;
  }

  const { renderer, renderWindow, widgetManager } = getState();

  // Setup orientation widget
  const majorAxis = (vec3, idxA, idxB) => {
    const axis = [0, 0, 0];
    const idx = Math.abs(vec3[idxA]) > Math.abs(vec3[idxB]) ? idxA : idxB;
    const value = vec3[idx] > 0 ? 1 : -1;
    axis[idx] = value;
    return axis;
  };

  const axes = vtkAxesActor.newInstance();
  const orientationWidget = vtkOrientationMarkerWidget.newInstance({
    actor: axes,
    interactor: renderWindow.getInteractor(),
  });
  orientationWidget.setEnabled(true);
  orientationWidget.setViewportCorner(vtkOrientationMarkerWidget.Corners.BOTTOM_LEFT);
  orientationWidget.setViewportSize(0.15);
  orientationWidget.setMinPixelSize(100);
  orientationWidget.setMaxPixelSize(300);

  // Setup interactive widget
  const widget = vtkInteractiveOrientationWidget.newInstance();
  // TODO: bounds not working. Because of this it seems the navigation cube is misplaced.
  widget.placeWidget(axes.getBounds());
  widget.setBounds(axes.getBounds());
  widget.setPlaceFactor(1);

  // Attach widget to manager
  const widgetInstance = widgetManager.addWidget(widget);
  // Manage user interaction
  widgetInstance.onOrientationChange(({ direction } /* up, direction, action, event */) => {
    const camera = renderer.getActiveCamera();
    const focalPoint = camera.getFocalPoint();
    const position = camera.getPosition();
    const viewUp = camera.getViewUp();   

    const distance = Math.sqrt(vtkMath.distance2BetweenPoints(position, focalPoint));
    camera.setPosition(
      focalPoint[0] + direction[0] * distance,
      focalPoint[1] + direction[1] * distance,
      focalPoint[2] + direction[2] * distance
    );

    if (direction[0]) {
      camera.setViewUp(majorAxis(viewUp, 1, 2));
    }
    if (direction[1]) {
      camera.setViewUp(majorAxis(viewUp, 0, 2));
    }
    if (direction[2]) {
      camera.setViewUp(majorAxis(viewUp, 0, 1));
    }

    orientationWidget.updateMarkerOrientation();
  });

  return true;
};

const self = {
  enableOrientationBox,
};
export default self;
