import { defer } from 'lodash-es';
import GeoJSON from 'ol/format/GeoJSON';
import { Vector as VectorSource } from 'ol/source.js';
import { FeatCollAny } from '../../../models/misc';
import { getEmitters } from '../../MikeVisualizerEvents';
import MikeVisualizerStore from '../../store/MikeVisualizerStore';
const { setState } = MikeVisualizerStore;

/**
 * Contains methods to emit events related to 2d draw data changes.
 *
 * @module MikeVisualizer2DEvents
 * @version 1.0.0
 *
 * @internal
 */

/**
 * Given a vector source, it emits changes once, triggering an update callback.
 * This is the general mechanism to emit drawing changes i.e. after each sketch update.
 *
 * It can also be called manually for more performance-intensive tasks (i.e. after a batch job).
 * For example, it is expensive to call it during big data imports (i.e. getting features from an external data source). In that case, it should be called once after all data has been imported.
 *
 * @param source Vector source to emit changes for.
 */
const _emitChanges = (source: VectorSource) => () => {
  // Deferring ensures that open layers went through the call stack and finished all work it has to do.
  defer(() => {
    const { emitDrawnDataUpdated } = getEmitters();
    const geojson = new GeoJSON().writeFeaturesObject(source.getFeatures()) as FeatCollAny;
    setState({ drawMapCurrentGeojson: geojson });
    emitDrawnDataUpdated(geojson);
  });
};

const self = {
  _emitChanges,
};

export default self;
