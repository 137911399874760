import Map from 'ol/Map';
import { ScaleLine, Attribution } from 'ol/control';
import MikeVisualizerStore from '../store/MikeVisualizerStore';
import MikeVisualizerUtil from '../MikeVisualizerUtil';
const { rendererReady } = MikeVisualizerUtil;

const { getState } = MikeVisualizerStore;

/**
 * Contains methods to enable 2d map controls.
 *
 * @module MikeVisualizer2DControls
 * @version 1.0.0
 *
 * @internal
 */

/**
 * Setup a scale control of type ScaleLine for the current basemap (if available), doing the following:
 * - removes all previous scalecontrols
 * - creates a LineScale control
 *
 * @param target The scale will be added to the target html element. If no target the scale will be added to the ol viewport
 *
 */
const setupMapScaleControl = async (target?: HTMLElement) => {
  if (!rendererReady()) {
    console.info('Attempted to setup map scale before the renderer was ready.');
    return false;
  }

  const runSetupMapScale = () => {
    const { baseMap } = getState();
    if (!baseMap) {
      console.warn(`'No base map`);
      return false;
    }
    _clearControlTarget(target);
    _removeAllMapScaleControls(baseMap);
    const scaleLine = new ScaleLine({
      units: 'metric',
      bar: false,
      target,
    });
    baseMap.addControl(scaleLine);
    return true;
  };

  const { baseMap: existingBaseMap, baseMapPromise } = getState();

  // if baseMap is already present setup scale immidiately
  if (existingBaseMap) {
    return runSetupMapScale();
  }

  // otherwise wait for promist to finish
  if (baseMapPromise) {
    return baseMapPromise.then(runSetupMapScale);
  }

  return false;
};

/**
 * On a target basemap, removes all map scale controls.
 *
 * @param map
 */
const _removeAllMapScaleControls = (map: Map) => {
  if (!map || !map.getControls()) {
    return;
  }
  const controls = [...map.getControls().getArray()];

  controls.forEach((control) => {
    if (control instanceof ScaleLine) {
      map.removeControl(control);
    }
  });
};

/**
 * Setup an  OpenLayers control of type Attribution for the current basemap (if available), doing the following:
 * - removes all previous attribution controls
 * - creates an Attribution control
 *
 * @param target The attribution will be added to the target html element. If no target the attribution will be added to the ol viewport
 *
 */
const setupMapAttributionControl = async (target?: HTMLElement) => {
  if (!rendererReady()) {
    console.info('Attempted to setup map attribution before the renderer was ready.');
    return false;
  }

  const runSetupMapAttribution = () => {
    const { baseMap } = getState();
    if (!baseMap) {
      console.error(`'No base map`);
      return false;
    }
    _clearControlTarget(target);
    _removeAllMapAttributionControls(baseMap);
    const attribution = new Attribution({
      target,
      collapsed: false,
      collapsible: false,
    });
    baseMap.addControl(attribution);
    return true;
  };

  const { baseMap: existingBaseMap, baseMapPromise } = getState();

  // if baseMap is already present setup attribution immidiately
  if (existingBaseMap) {
    return runSetupMapAttribution();
  }

  // otherwise wait for promise to finish
  if (baseMapPromise) {
    return baseMapPromise.then(runSetupMapAttribution);
  }

  return false;
};

/**
 * On a target basemap, removes all map attribution controls.
 *
 * @param map
 */
const _removeAllMapAttributionControls = (map: Map) => {
  if (!map || !map.getControls()) {
    return;
  }
  const controls = [...map.getControls().getArray()];

  controls.forEach((control) => {
    if (control instanceof Attribution) {
      map.removeControl(control);
    }
  });
};

/**
 * Clears the Control from its target.
 *
 * @param target
 */
const _clearControlTarget = (target?: HTMLElement) => {
  if (target) {
    target.innerHTML = '';
  }

  return target;
};

const self = {
  setupMapScaleControl,
  setupMapAttributionControl,
};

export default self;
