import React, { useMemo } from 'react';
import { IProgressItem } from '../IProgressItem';
import { makeStyles, createStyles, Typography, ButtonBase, Tooltip } from '@material-ui/core';
import { MikeProgressBar } from './MikeProgressBar';
import { IMikeTheme } from '../../mike-shared-styles';
import { css } from 'emotion';
import { ReactComponent as Delete } from "icons/Delete"
import classNames from 'classnames';

const useStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    root: {
      display: 'flex'
    },
    progressSection: {
      width: '100%',
      margin: '10px 0 16px 0',
      '&:first-of-type': {
        marginTop: '0',
      },
      '&:last-of-type': {
        marginBottom: '0',
      },
    },
    progressSectionWithButton: {
      width: '90%',
      margin: '10px 0 16px 0',
      '&:first-of-type': {
        marginTop: '0',
      },
      '&:last-of-type': {
        marginBottom: '0',
      },
    },
    progressErrorText: {
      color: theme.palette.error.main,
    }
  });
});

const ellip = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  max-width: 90vw;
`;

interface IProps extends React.HTMLAttributes<HTMLElement> {
  progressItem: IProgressItem;
  onClickItemButton?: (id: string) => {};
}

/**
 * @name ProgressSection
 * @summary Renders a section of the MikeTopbarDropdown that should be removed after
 * a delay once it's done or has failed.
 * Not intended to be used without MikeProgressBar.
 */
function ProgressSection(props: IProps) {
  const { progressItem, className, onClickItemButton } = props;
  const classes = useStyles();
  const labelClass = progressItem.error ? classes.progressErrorText : '';

  const showButton = useMemo(() => {
    return onClickItemButton !== undefined && progressItem.canBeDeleted
  }, [onClickItemButton, progressItem]) 

  const item = () => {
    return (
    <div className={`${showButton ? classes.progressSectionWithButton: classes.progressSection} ${className || ''}`}>
      <Tooltip title={progressItem.title}>
      <Typography className={classNames(labelClass, ellip)} variant="body2">
        
         {progressItem.title}
       
      </Typography>
      </Tooltip>
      <MikeProgressBar
        variant="determinate"
        color="secondary"
        value={progressItem.progressValue}
        style={{ marginTop: '7px' }}
      />
    </div>)
  }

  return (
    showButton ?
    <div className={classes.root}>
      {item()}      
      <ButtonBase onClick={() => onClickItemButton && onClickItemButton(progressItem.id)}>
        <Delete />
      </ButtonBase>     
    </div> : item()
  ); 
}

export default ProgressSection;
