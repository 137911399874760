import React from 'react';
import { formatDate } from '../../mike-shared-helpers/date';
import Tooltip from '@material-ui/core/Tooltip';

interface IProps {
  jsonTime: string;
}

const DateTime = (props: IProps) => {
  const { jsonTime } = props;

  const dateTime = formatDate(jsonTime, true);
  const date = formatDate(jsonTime, false);
  return (
    <Tooltip title={dateTime}>
      <div style={{ whiteSpace: 'nowrap' }}>{date}</div>
    </Tooltip>
  );
};

export default React.memo(DateTime);
