import { isEqual, noop } from 'lodash-es';

import { defaults } from 'ol/interaction.js';
import Map from 'ol/Map';
import View from 'ol/View';

import MikeVisualizerStore from '../../store/MikeVisualizerStore';
import MikeVisualizer2DMapUtil from '../MikeVisualizer2DMapUtil';
import MikeVisualizerUtil from '../../MikeVisualizerUtil';
import { IMapProperties } from '../../IMikeVisualizerModels';
import { MAP_IDS, VIEWER_ZINDEX } from '../../MikeVisualizerConstants';
import { bindInteractorEvents } from '../../vtk-extensions/vtkBasicFullScreenRenderWindow';

const { rendererReady } = MikeVisualizerUtil;
const { getState, setState } = MikeVisualizerStore;
const {
  _getViewerProperties,
  _getEpsgString,
  _verifyProjection,
  _zoomAndCenterOpenLayersView,
  _removeAllMapInteractions,
} = MikeVisualizer2DMapUtil;

let subscriptions: Array<any> = []; // Local interactor subscriptions. These should be managed within this module and cleared upon destroy.

/**
 * Contains methods required for creating a 2d data map & setting up all default interactions.
 * The setup methods are typically called behind the scenes, when i.e. adding data to the map.
 *
 * While active, it allows adding, updating and removing data from an OpenLayers Map instance.
 * The drawing tools OpenLayer instance does not have a datamap (it is transparent).
 *
 * @note This component is an addon. It is not needed for the MikeVisualizer to function. It can be dynamically imported anywhere in the MikeVisualizer or outside of it.
 *
 * @module MikeVisualize2DDataCore
 * @version 1.0.0
 *
 * @internal
 */

/**
 * Moves the data map to the position (center & zoom) that matches the 3D viewer's bounds (current corners of the canvas).
 * Requires a data map to be setup.
 * This method won't update the map if data map properties (bbox, height, width, etc) haven't changed.
 *
 * @private
 */
const _syncDataMapTo3DViewerBounds = () => {
  const { dataMapProperties, dataMap } = getState();
  const viewerProperties = _getViewerProperties();
  if (!dataMap) {
    return false;
  }
  if (viewerProperties && !isEqual(viewerProperties, dataMapProperties)) {
    const view = dataMap.getView();
    _zoomAndCenterOpenLayersView(view, viewerProperties as IMapProperties);
    setState({ dataMapProperties: viewerProperties as IMapProperties });
  }
  return true;
};

/**
 * Creates an OpenLayers map instance.
 * Event listeners are also setup for interactions on the 3D viewer & resize.
 * Whenever the 3D viewer is interacted with, the map position syncs accordingly.
 *
 * The listeners `unsubscribe` methods are stored so they can be called when destroying the map.
 *
 * @public
 */
const _getOrSetupOpenLayersDataMap = async () => {
  if (!rendererReady()) {
    console.debug('Attempted to setup data map before the renderer was ready.');
    return false;
  }

  const {
    container: vtkContainer,
    renderWindow,
    fullScreenRenderWindow,
    epsgCode,
    dataMapPromise: existingDataMapPromise,
  } = getState();
  const epsgString = _getEpsgString(epsgCode);

  if (existingDataMapPromise) {
    return existingDataMapPromise;
  }

  const dataMapPromise: Promise<Map> = new Promise(async (resolve) => {
    const mapContainer = document.createElement('div');

    // Verify given projection and try to fetch it if not defined.
    try {
      await _verifyProjection(epsgCode);
    } catch (error) {
      console.error('Failed to setup data map.', error);
      return false;
    }

    // Create a view for the given projection. The view is what reprojects the tiles.
    const view = new View({
      projection: epsgString,
      center: [0, 0],
      zoom: 5,
    });

    // Create a HTMLElement container where OpenLayers will add canvas, controls, etc. The container should be removed when destroyed.
    mapContainer.style.cssText = `
      width: 100%;
      height: 100%;
      z-index: ${VIEWER_ZINDEX.DATAMAP};
      opacity: 1;
    `;
    mapContainer.id = MAP_IDS.DATAMAP_CONTAINER_ID;
    (vtkContainer as HTMLElement).appendChild(mapContainer);

    const map = new Map({
      controls: [],
      layers: [],
      target: mapContainer,
      view,
      interactions: defaults({
        altShiftDragRotate: false,
        onFocusOnly: false,
        // constrainResolution: false, // Not a valid option
        doubleClickZoom: false,
        keyboard: false,
        mouseWheelZoom: false,
        shiftDragZoom: false,
        dragPan: false,
        pinchRotate: false,
        pinchZoom: false,
        zoomDelta: undefined,
        zoomDuration: undefined,
      }),
    });

    _removeAllMapInteractions(map);

    const interactor = renderWindow.getInteractor();
    const defaultSubscriptions = bindInteractorEvents(interactor, mapContainer);

    subscriptions = [
      ...subscriptions,
      interactor.onStartMouseWheel(_syncDataMapTo3DViewerBounds),
      interactor.onEndMouseWheel(_syncDataMapTo3DViewerBounds),
      interactor.onMouseWheel(_syncDataMapTo3DViewerBounds),
      interactor.onPinch(_syncDataMapTo3DViewerBounds),
      interactor.onPan(_syncDataMapTo3DViewerBounds),
      interactor.onMouseMove(_syncDataMapTo3DViewerBounds),
      ...defaultSubscriptions, // Also keep track of default bindings to unsubscribe later
    ];

    // TODO: (joel) TS error; dataMapProperties didn't containt an epsgCode member
    setState({ dataMap: map, dataMapProperties: { epsgCode } });

    // Make sure the map is updated when the window is resized.
    fullScreenRenderWindow.setResizeCallback(_syncDataMapTo3DViewerBounds);

    // Do an initial sync with the 3D viewer
    self._syncDataMapTo3DViewerBounds();

    resolve(map);
  });

  setState({ dataMapPromise });
  return dataMapPromise;
};

/**
 * Removes the open layer datamap and:
 *  - unsubscribes from all map events
 *  - removes the HTMLElement used as a container
 *  - updates state and clears data map properties
 *
 * @public
 */
const _destroyOpenLayersDataMap = () => {
  if (!rendererReady()) {
    console.info('Attempted to destroy data map before the renderer was ready.');
    return false;
  }

  const { container: vtkContainer, dataMap, renderWindow, fullScreenRenderWindow } = getState();
  const mapContainer = (vtkContainer as HTMLElement).querySelector(
    `#${MAP_IDS.DATAMAP_CONTAINER_ID}`
  );

  if (!mapContainer) {
    console.info('Dropped removing data map because the map container is not in the DOM anymore.');
    return false;
  }

  try {
    subscriptions = subscriptions.filter((subscription) => subscription.unsubscribe());
    dataMap ? dataMap.setTarget(undefined) : noop();
    (vtkContainer as HTMLElement).removeChild(mapContainer);

    /**
     * NB: it's important to re-bind events to the 'real viewer' aka the 3D renderer.
     * Keep in mind that the interactor can only be binded to one container at a time and that has to be either the open layers one while drawing or the 3D renderer otherwise.
     */
    bindInteractorEvents(renderWindow.getInteractor(), fullScreenRenderWindow.getContainer());

    setState({
      dataMapPromise: null,
      dataMapProperties: null,
      dataMap: null,
      dataMapUrl: null,
    }); // Always set state after interactor reset!

    return true;
  } catch (e) {
    console.error('Failed to remove data map.', e);
    return false;
  }
};

/**
 * This method is intended to be called from external modules.
 * For now, it triggers a map change callback, but in the future it might cancel or reset other map properties.
 *
 * @public
 */
const forceOpenLayersDataMapUpdate = () => self._syncDataMapTo3DViewerBounds();

const self = {
  _syncDataMapTo3DViewerBounds,
  _getOrSetupOpenLayersDataMap,
  _destroyOpenLayersDataMap,

  forceOpenLayersDataMapUpdate,
};

export default self;
