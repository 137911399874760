import proj4 from 'proj4';
import { register } from 'ol/proj/proj4.js';

import MikeVisualizerStore from '../store/MikeVisualizerStore';
import MikeVisualizer2DMapUtil from './MikeVisualizer2DMapUtil';

const { _getEpsgString } = MikeVisualizer2DMapUtil;
const { getState, setState } = MikeVisualizerStore;

/**
 * Manages projections.
 *
 * @module MikeVisualizerProjections
 * @version 1.0.0
 */

export const COMMON_PROJECTIONS = [
  ['EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs'], // WGS84
  [
    'EPSG:3857',
    '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs',
  ], // Web mercator
  ['EPSG:23032', '+proj=utm +zone=32 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs'], // UTM zone 32N
  [
    'EPSG:3138',
    '+proj=tmerc +lat_0=0 +lon_0=31 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
  ], // Europe - onshore and offshore
  [
    'EPSG:27700',
    '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs',
  ], // United Kingdom (UK)
  [
    'EPSG:29901',
    '+proj=tmerc +lat_0=53.5 +lon_0=-8 +k=1 +x_0=200000 +y_0=250000 +ellps=airy +towgs84=482.5,-130.6,564.6,-1.042,-0.214,-0.631,8.15 +units=m +no_defs',
  ], // Irish National Grid
  [
    'EPSG:2154',
    '+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
  ], // France
  [
    'EPSG:5514',
    '+proj=krovak +lat_0=49.5 +lon_0=24.83333333333333 +alpha=30.28813972222222 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=589,76,480,0,0,0,0 +units=m +no_defs',
  ], // Czech Republic; Slovakia.
  [
    'EPSG:5243',
    '+proj=lcc +lat_1=48.66666666666666 +lat_2=53.66666666666666 +lat_0=51 +lon_0=10.5 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
  ], // Europe - onshore and offshore
  [
    'EPSG:4839',
    '+proj=lcc +lat_1=48.66666666666666 +lat_2=53.66666666666666 +lat_0=51 +lon_0=10.5 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
  ], // Europe - onshore and offshore
  [
    'EPSG:23095',
    '+proj=tmerc +lat_0=0 +lon_0=5 +k=0.9996 +x_0=500000 +y_0=0 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs',
  ], // ED50 / TM 5 NE
  [
    'EPSG:28992',
    '+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.417,50.3319,465.552,-0.398957,0.343988,-1.8774,4.0725 +units=m +no_defs',
  ], // Netherlands
  [
    'EPSG:21781',
    '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=600000 +y_0=200000 +ellps=bessel +towgs84=674.4,15.1,405.3,0,0,0,0 +units=m +no_defs',
  ], // Liechtenstein; Switzerland.
  [
    'EPSG:5641',
    '+proj=merc +lon_0=-43 +lat_ts=-2 +x_0=5000000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
  ], // Central America and South America, onshore and offshore
  [
    'EPSG:2193',
    '+proj=tmerc +lat_0=0 +lon_0=173 +k=0.9996 +x_0=1600000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
  ], // New Zealand
  [
    'EPSG:27200',
    '+proj=nzmg +lat_0=-41 +lon_0=173 +x_0=2510000 +y_0=6023150 +ellps=intl +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +units=m +no_defs',
  ], // NZGD49 / New Zealand Map Grid -- New Zealand Map Grid (NZMG)
  [
    'EPSG:3112',
    '+proj=lcc +lat_1=-18 +lat_2=-36 +lat_0=0 +lon_0=134 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
  ], // GDA94 / Geoscience Australia Lambert
];

/**
 * Set epsg code.
 *
 * @param epsgCode
 */
const setEpsgCode = (epsgCode: number) => {
  setState({ epsgCode });
};

/**
 * Get the currently set epsg code.
 */
const getEpsgCode = () => getState().epsgCode;

/**
 * Set crs string.
 * Alternative to epsg.
 *
 * @param { string } crs
 */
const setCrs = (crs: string, epsgCode?: number) => {
  const fakeEpsgCode = epsgCode ? epsgCode : -1;

  // Register crs with proj4js.
  proj4.defs(_getEpsgString(fakeEpsgCode), crs);
  register(proj4);

  // Set custom/fake epsg code to state, so other modules (ol) can use it for i.e. map reprojection.
  setEpsgCode(fakeEpsgCode);
  setState({ crs });
};

/**
 * Get crs string.
 *
 * @param { string } crs
 */
const getCrs = () => getState().crs;

const self = {
  setEpsgCode,
  getEpsgCode,
  setCrs,
  getCrs,
};

export default self;
