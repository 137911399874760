import React, { useRef, useState, useEffect } from 'react';
import { css } from 'emotion';
import mikeSharedTheme from '../mike-shared-styles';

const PanelHeaderContainerStyle = css`
  background: white;
  z-index: 50;
`;

const PanelHeaderContainerWithShadowStyle = css`
  box-shadow: ${mikeSharedTheme.shadows[3]};
`;

type PanelHeaderContainerProps = {
  children?: React.ReactNode;
};

export const useSticky = (ref: any) => {
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    /* A trick to make Intersection observer work */

    if (ref && ref.current) {
      ref.current.style.top = '-1px';
      ref.current.style.position = 'sticky';
    }
  }, [ref]);

  useEffect(() => {
    const cachedRef = ref.current;
    const observer = new IntersectionObserver(([event]) => setSticky(event.intersectionRatio < 1), {
      threshold: [1],
    });

    observer.observe(cachedRef);

    return function() {
      observer.unobserve(cachedRef);
    };
  }, [ref]);

  return [sticky];
};

/**
 * @name MikeStickyPanelHeaderContainer
 * @summary A header wrapper that is meant to be used for panel headers that should be sticky.
 * Generally, there doesn't seem to be many rules on what it can contain, so it can be sometimes just a header, other times header & tabs, other times header, tabs and i.e. filter controls.
 * Must be placed inside a panel.
 *
 * @param props
 */
export const MikeStickyPanelHeaderContainer = (props: PanelHeaderContainerProps) => {
  const container = useRef<HTMLDivElement>(null);
  const [sticky] = useSticky(container);

  return (
    <div
      ref={container}
      className={[
        PanelHeaderContainerStyle,
        sticky ? PanelHeaderContainerWithShadowStyle : '',
      ].join(' ')}
    >
      {props.children}
    </div>
  );
};
