import { IVtkDataObject, IExtent, IOrigin, ISpacing } from '../IMikeVisualizerModels';

/**
 * Writes vtk objects for given data.
 *
 * @module MikeVisualizerVtkWriter
 * @version 1.0.0
 */

/**
 * Given a list of offsets and their connectivity, it connects them to form the actual connected data.
 *
 * @param offsets A list of offset points (as per vtk DataArray[Name="offsets"])
 * @param connectivity A list of connectivity points (as per vtk DataArray[Name="connectivity"])
 */
const _connectOffsets = (offsets: Array<number>, connectivity: Array<number>): Uint32Array => {
  const connected = new Uint32Array(offsets.length + connectivity.length);
  let writeOffset = 0;
  let previousOffset = 0;
  offsets.forEach((v) => {
    const cellSize = v - previousOffset;
    connected[writeOffset++] = cellSize;

    for (let i = 0; i < cellSize; i++) {
      connected[writeOffset++] = connectivity[previousOffset + i];
    }

    // save previous offset
    previousOffset = v;
  });

  return connected;
};

/**
 * Creates a vtk object that can be set as input data to a mapper.
 * i.e. `mapper.setInputData(vtkObject);`
 *
 * This can be read directly by vtk and bipass parsing and decoding of xml.
 * Note: not very well documented, but here are a couple examples:
 * - [Embedded data](https://github.com/Kitware/vtk-js/blob/master/Sources/Testing/Examples/PolyDataSerialization/example/index.js#L23)
 * - [Remote data](https://github.com/Kitware/vtk-js/blob/master/Data/scene/2/index.json)
 *
 * @param noDataValue
 * @param vtkFieldData
 * @param vtkDataArrays
 * @param vtkPoints
 * @param [extent] Not required for vtkPolyData
 * @param [origin] Not required for vtkPolyData
 * @param [spacing] Not required for vtkPolyData
 * @param [polyOffsets]
 * @param [polyConnectivity]
 * @param [vertOffsets]
 * @param [vertConnectivity]
 */
const createVtkObject = (
  vtkClass: 'vtkPolyData' | 'vtkImageData',
  noDataValue: number | string,
  vtkFieldData: Array<IVtkDataObject>,
  vtkDataArrays: Array<IVtkDataObject>,
  vtkPoints: Array<number | string>,
  extent?: IExtent,
  origin?: IOrigin,
  spacing?: ISpacing,
  polyOffsets?: Array<number>,
  polyConnectivity?: Array<number>,
  vertOffsets?: Array<number>,
  vertConnectivity?: Array<number>
) => {
  return {
    vtkClass,

    ...(extent && { extent }),
    ...(origin && { origin }),
    ...(spacing && { spacing }),

    fieldData: {
      vtkClass: 'vtkDataSetAttributes',
      arrays: vtkFieldData.map((fieldDataArray) => {
        return {
          data: {
            vtkClass: 'vtkDataArray',
            dataType: 'Float32Array',
            numberOfComponents: 1,
            name: fieldDataArray.getName(),
            values: fieldDataArray.getData(),
          },
        };
      }),
    },

    points: {
      vtkClass: 'vtkPoints',
      dataType: 'Float32Array',
      numberOfComponents: 3,
      values: vtkPoints,
    },

    pointData: {
      vtkClass: 'vtkDataSetAttributes',
      arrays: vtkDataArrays.map((dataArray) => {
        return {
          data: {
            vtkClass: 'vtkDataArray',
            dataType: 'Float32Array',
            numberOfComponents: 1,
            name: dataArray.getName(),
            values: dataArray.getData().map((v) => (v === noDataValue ? 'NaN' : v)),
          },
        };
      }),
    },

    polys: {
      vtkClass: 'vtkCellArray',
      dataType: 'Uint32Array',
      numberOfComponents: 1,
      values:
        polyOffsets && polyConnectivity ? self._connectOffsets(polyOffsets, polyConnectivity) : [],
    },

    verts: {
      vtkClass: 'vtkCellArray',
      dataType: 'Uint32Array',
      numberOfComponents: 1,
      values:
        vertOffsets && vertConnectivity ? self._connectOffsets(vertOffsets, vertConnectivity) : [],
    },
  };
};

/**
 * Generates vtk xml from vtk data.
 * An inefficient version of createVtkObject, which requires xml parsing by vtk.js.
 * Useful for debug purposes.
 * NB: missing extent, origin, spacing.
 *
 * @param totalNumberOfPoints
 * @param numberOfPolys
 * @param noDataValue
 * @param vtkFieldData
 * @param vtkDataArrays
 * @param vtkPoints
 * @param [polyOffsets]
 * @param [polyConnectivity]
 * @param [vertOffsets]
 * @param [vertConnectivity]
 */
const createVtkXmlObject = (
  totalNumberOfPoints: number,
  noOfPolys: number,
  noDataValue: number | string,
  vtkFieldData: Array<IVtkDataObject>,
  vtkDataArrays: Array<IVtkDataObject>,
  vtkPoints: Array<number | string>,
  polyOffsets?: Array<number>,
  polyConnectivity?: Array<number>,
  vertOffsets?: Array<number>,
  vertConnectivity?: Array<number>
) => {
  // Compute other constants needed to generate the vtp XML file.
  const NUMBER_OF_VTI_POINTS = totalNumberOfPoints;

  const CONNECTIVITY_RANGE_MIN = 0;
  const CONNECTIVITY_RANGE_MAX = totalNumberOfPoints - 1;

  const NUMBER_OF_VERTS = 1;
  const VERT_OFFSET_MIN = totalNumberOfPoints;
  const VERT_OFFSET_MAX = totalNumberOfPoints;

  const POLY_OFFSET_MIN = 3;
  const POLY_OFFSET_MAX = (totalNumberOfPoints - 1) * 3;

  return `<?xml version="1.0"?>
    <VTKFile type="PolyData" version="0.1" byte_order="LittleEndian" header_type="UInt32">
      <PolyData>
        <FieldData>
          ${vtkFieldData.map((fieldDataArray) => {
            const data = fieldDataArray.getData();

            const DATA_ARRAY_NAME = fieldDataArray.getName();
            const VTI_POINT_RANGE = fieldDataArray.getRange();
            const POINT_RANGE_MIN = VTI_POINT_RANGE[0];
            const POINT_RANGE_MAX = VTI_POINT_RANGE[1];

            return `<DataArray type="Float32" Name="${DATA_ARRAY_NAME}" NumberOfTuples="1" format="ascii" RangeMin="${POINT_RANGE_MIN}" RangeMax="${POINT_RANGE_MAX}">
                ${data.join(' ')}
              </DataArray>`;
          })}
        </FieldData>

        <Piece NumberOfPoints="${NUMBER_OF_VTI_POINTS}" NumberOfVerts="${NUMBER_OF_VERTS}" NumberOfLines="0" NumberOfStrips="0" NumberOfPolys="${noOfPolys}">
          <CellData>

          </CellData>

          <Lines>

          </Lines>

          <Strips>

          </Strips>

          <PointData>
            ${vtkDataArrays.map((dataArray) => {
              const data = dataArray.getData();

              const DATA_ARRAY_NAME = dataArray.getName();
              const VTI_POINT_RANGE = dataArray.getRange();
              const POINT_RANGE_MIN = VTI_POINT_RANGE[0];
              const POINT_RANGE_MAX = VTI_POINT_RANGE[1];

              return `<DataArray type="Float32" Name="${DATA_ARRAY_NAME}" format="ascii" RangeMin="${POINT_RANGE_MIN}" RangeMax="${POINT_RANGE_MAX}">
                  ${data.map((v) => (v === noDataValue ? 'NaN' : v)).join(' ')}
                </DataArray>`;
            })}
          </PointData>

          <Points>
            <DataArray type="Float32" Name="Points" NumberOfComponents="3" format="ascii">
              ${vtkPoints.join(' ')}
            </DataArray>
          </Points>

          <Polys>
            ${
              polyConnectivity && polyOffsets
                ? `<DataArray type="Int32" Name="connectivity" format="ascii" RangeMin="${CONNECTIVITY_RANGE_MIN}" RangeMax="${CONNECTIVITY_RANGE_MAX}">
                ${polyConnectivity.join(' ')}
              </DataArray>

              <DataArray type="Int32" Name="offsets" format="ascii" RangeMin="${POLY_OFFSET_MIN}" RangeMax="${POLY_OFFSET_MAX}">
                ${polyOffsets.join(' ')}
              </DataArray>`
                : ''
            }
          </Polys>

          <Verts>
            ${
              vertConnectivity && vertOffsets
                ? `<DataArray type="Int32" Name="connectivity" format="ascii" RangeMin="${CONNECTIVITY_RANGE_MIN}" RangeMax="${CONNECTIVITY_RANGE_MAX}">
                    ${vertConnectivity.join(' ')}
                  </DataArray>

                  <DataArray type="Int32" Name="offsets" format="ascii" RangeMin="${VERT_OFFSET_MIN}" RangeMax="${VERT_OFFSET_MAX}">
                    ${vertOffsets.join(' ')}
                  </DataArray>`
                : ''
            }
          </Verts>
        </Piece>
      </PolyData>
    </VTKFile>`;
};

const self = {
  _connectOffsets,
  createVtkObject,
  createVtkXmlObject,
};
export default self;
