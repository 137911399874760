import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography, Link } from '@material-ui/core/';
import { INotification } from './INotification';
import { IMikeTheme } from '../mike-shared-styles';
import { formatDate } from '../mike-shared-helpers/date';

interface IProps extends React.HTMLAttributes<HTMLElement> {
  /**
   * Notifications of one day.
   */
  notification: INotification;
  /**
   * Link href.
   */
  href?: string;
  /**
   * Link callback.
   */
  onLinkCallBack?: (arg0: INotification) => void;
  /**
   * Custom render function.
   * To display the content of a notification item.
   */
  renderContent?: (notification: INotification) => {};
  /**
   * Custom render function.
   * To display the title of a notification item.
   */
  renderTitle?: (notification: INotification) => {};
}

const useStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    notificationItem: {
      paddingBottom: theme.spacing(1),
    },
    notificationTitle: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      fontWeight: 'bold',
    },
    notificationText: {
      paddingBottom: theme.spacing(1),
    },
    notificationLink: {
      cursor: 'pointer ',
      fontSize: '12px',
    },
  });
});

/**
 * @name MikeNotificationItem
 * @summary A single notification icon, meant to be rendered by a notification item group.
 */
export const MikeNotificationItem = ({
  notification,
  href,
  onLinkCallBack,
  renderContent,
  renderTitle,
}: IProps) => {
  const classes = useStyles();
  const title = notification.title;

  const handleClick = (currentNotification: INotification) => (e: any) => {
    if (onLinkCallBack) {
      e.preventDefault();
      onLinkCallBack(currentNotification);
    }
  };

  return (
    <div className={classes.notificationItem}>
      {renderTitle ? (
        renderTitle(notification)
      ) : (
        <Typography className={classes.notificationTitle} variant="body2">
          {title}
        </Typography>
      )}
      {renderContent ? (
        renderContent(notification)
      ) : (
        <>
          {notification.message && (
            <Typography className={classes.notificationText} variant="body2">
              {notification.message}
            </Typography>
          )}
          <Typography className={classes.notificationText} variant="body2">
            {formatDate(notification.time, true, true)}
          </Typography>
        </>
      )}
      {notification.link && (
        <Link
          href={href}
          target="_blank"
          rel="noopener"
          onClick={handleClick(notification)}
          className={classes.notificationLink}
        >
          {notification.linkLabel ? notification.linkLabel : 'See details'}
        </Link>
      )}
    </div>
  );
};

export default MikeNotificationItem;
