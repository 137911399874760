// From https://kitware.github.io/vtk-js/examples/OrientationMarkerWidget.html
import vtkOrientationMarkerWidget from 'vtk.js/Sources/Interaction/Widgets/OrientationMarkerWidget';
import vtkAnnotatedCubeActor from 'vtk.js/Sources/Rendering/Core/AnnotatedCubeActor';
import MikeVisualizerStore from './store/MikeVisualizerStore';
import MikeVisualizerUtil from './MikeVisualizerUtil';
import { vtkOrientationMakerWidgetCorners } from './models/IWidgetModels';
import MIKE_COLORS from '../../../mike-shared-styles/mike-colors';
const { getState } = MikeVisualizerStore;
const { rendererReady } = MikeVisualizerUtil;

const DEFAULT_STYLE = {
  text: '?',
  fontStyle: 'bold',
  fontFamily: 'Arial',
  fontColor: '#ffffff',
  fontSizeScale: (res) => res / 3.5,
  faceColor: MIKE_COLORS.BRANDBLUE_DEFAULT,
  faceRotation: 0,
  edgeThickness: 0.1,
  edgeColor: '#ffffff',
  resolution: 400,
};
const DEFAULT_SIZE = 80;
type Style = Partial<typeof DEFAULT_STYLE>;

/**
 * A cube widget showing orientation in 3D - it's VTK's `OrientationMarkerWidget`.
 * Features DHI colours.
 *
 * @param args Arguments as { <options> }, all optional, see below:
 * @param corner Placement of widget, type `vtkOrientationMakerWidgetCorners`.
 * @param pixelSize Fixed size or size span as number of pixels; format `number | { min: number; max: number }`.
 * @param viewPortSize Widget size 0.0-1.0 as part of the VTK viewport.
 *  Overruled by pixelSize in most cases. The widget size will scale with the viewport size when inside the size span of pixelSize.
 * @param defaultEnabled Enable widget when mounting it; default is true.
 * @param defaultStyle `Style` for the entire widget.
 * @param xPlusFaceProperty `Style` East side properties.
 * @param xMinusFaceProperty `Style` West side properties.
 * @param yPlusFaceProperty `Style` North side properties.
 * @param yMinusFaceProperty `Style` South side properties.
 * @param zPlusFaceProperty `Style` Upper side properties.
 * @param zMinusFaceProperty `Style` Down side properties.
 *
 * @type `Style`, see source code / TypeScript intellisense.
 *
 * @returns A widget handler, use the functions to switch the widget on/off. Format `{ success: boolean; enable?: () => void; disable?: () => void; }`.
 */
const enableMikeOrientationMarkerWidget = ({
  corner = vtkOrientationMarkerWidget.Corners.BOTTOM_RIGHT,
  pixelSize,
  viewPortSize = 0.1,
  defaultStyle,
  xPlusFaceProperty,
  xMinusFaceProperty,
  yPlusFaceProperty,
  yMinusFaceProperty,
  zPlusFaceProperty,
  zMinusFaceProperty,
  defaultEnabled = true,
}: {
  corner?: vtkOrientationMakerWidgetCorners;
  pixelSize?: number | { min: number; max: number };
  viewPortSize?: number;
  defaultStyle?: Style;
  xPlusFaceProperty?: Style;
  xMinusFaceProperty?: Style;
  yPlusFaceProperty?: Style;
  yMinusFaceProperty?: Style;
  zPlusFaceProperty?: Style;
  zMinusFaceProperty?: Style;
  defaultEnabled?: boolean;
}): {
  /**
   * Whether or not the widget was created.
   */
  success: boolean;
  /**
   * Enable the widget.
   */
  enable: () => void;
  /**
   * Disable the widget.
   */
  disable: () => void;
  /**
   * Cannot make this more specific because VTK.js doesn't do types, sorry.
   */
  instance?: any;
} => {
  if (!rendererReady()) {
    return {
      enable: () => console.info(`Renderer not ready`),
      disable: () => console.info(`Renderer not ready`),
      success: false,
    };
  }
  const { renderer, renderWindow } = getState();

  // create axes
  const axes = vtkAnnotatedCubeActor.newInstance();
  // Set resolution to pixelSize / .max if not specified:
  let resolution = typeof pixelSize === 'number' ? pixelSize : DEFAULT_SIZE;
  resolution = typeof pixelSize === 'object' && pixelSize.max ? pixelSize.max : resolution;
  resolution = defaultStyle && defaultStyle.resolution ? defaultStyle.resolution : resolution;
  // add styles
  const baseStyle = Object.assign({}, DEFAULT_STYLE, { resolution }, defaultStyle);
  axes.setDefaultStyle(baseStyle);
  axes.setXPlusFaceProperty(Object.assign({}, baseStyle, { text: 'east' }, xPlusFaceProperty));
  axes.setXMinusFaceProperty(Object.assign({}, baseStyle, { text: 'west' }, xMinusFaceProperty));
  axes.setYPlusFaceProperty(Object.assign({}, baseStyle, { text: 'north' }, yPlusFaceProperty));
  axes.setYMinusFaceProperty(Object.assign({}, baseStyle, { text: 'south' }, yMinusFaceProperty));
  axes.setZPlusFaceProperty(Object.assign({}, baseStyle, { text: 'up' }, zPlusFaceProperty));
  axes.setZMinusFaceProperty(Object.assign({}, baseStyle, { text: 'down' }, zMinusFaceProperty));

  // create orientation widget
  const orientationWidget = vtkOrientationMarkerWidget.newInstance({
    actor: axes,
    interactor: renderWindow.getInteractor(),
  });
  orientationWidget.setEnabled(defaultEnabled);
  orientationWidget.setViewportCorner(corner);
  orientationWidget.setViewportSize(viewPortSize);

  // Set widget size range according to arguments.
  // Equal min/max results in constant size.
  let [minPixSize, maxPixSize] = [DEFAULT_SIZE, DEFAULT_SIZE];
  if (typeof pixelSize === 'number') {
    [minPixSize, maxPixSize] = [pixelSize, pixelSize];
  } else if (pixelSize && pixelSize.min && pixelSize.max) {
    [minPixSize, maxPixSize] = [pixelSize.min, pixelSize.max];
  }
  orientationWidget.setMinPixelSize(minPixSize);
  orientationWidget.setMaxPixelSize(maxPixSize);
  // `updateViewport` necessary to for size range to take effect from 1st render:
  orientationWidget.updateViewport();

  renderer.resetCamera();
  renderWindow.render();

  return {
    success: true,
    enable: () => {
      orientationWidget.setEnabled(true);
      orientationWidget.updateViewport();
    },
    disable: () => {
      orientationWidget.setEnabled(false);
      orientationWidget.updateViewport();
    },
    instance: orientationWidget,
  };
};

export default enableMikeOrientationMarkerWidget;
